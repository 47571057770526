<template>
  <v-container class="mt-12">
    <label for="">Chat Name</label>
    <v-text-field outlined v-model="chat_name"></v-text-field>
    <label for="">Message</label>
    <v-text-field
      outlined
      v-model="msg"
      @keyup.enter="saveMessage"
    ></v-text-field>

    <ul>
      <li v-for="(msg, i) in messages" :key="i">
        {{ msg }}
      </li>
    </ul>
  </v-container>
</template>

<script>
import PUSHER from "pusher-js";
import SERVICE from "../../services/index";
export default {
  data() {
    return {
      chat_name: "men",
      msg: "",
      messages: [],
      channel: null,
    };
  },
  async created() {
    this.pusher = await new PUSHER("5cfc84ffa9929a859a62", {
      cluster: "ap1",
    });

    this.channel = await this.pusher.subscribe(this.chat_name);
    this.channel.bind("MessageSent", async (data) => {
      console.log("data", data);
      this.messages.push(data.message);
    });
  },

  methods: {
    async saveMessage() {
      await SERVICE.chat
        .testSendMsg({ message: this.msg, chat_name: this.chat_name })
        .then(() => {});
    },
  },
};
</script>

<style></style>
