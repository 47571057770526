import Service from "../../services/index";

const state = {
  categories: [],
  isRender: false,
  paginate: {
    page: 0,
    itemsPerPage: 0,
    pageStart: 0,
    pageStop: 0,
    pageCount: 0,
  },
  article: [],
};

const getters = {};
const actions = {
  // eslint-disable-next-line no-unused-vars
  show({ commit }, id) {
    return Service.media.show(id).then((response) => {
      return response;
    });
  },
  // eslint-disable-next-line no-unused-vars
  get({ commit }, data) {
    return Service.media.get(data).then((response) => {
      commit("SET_ARTICLE", response.data);
      commit("SET_PAGINATE", {
        page: response.current_page,
        itemsPerPage: response.per_page,
        pageStart: response.from,
        pageStop: response.last_page,
        pageCount: Math.ceil(response.total / response.per_page),
      });
      return response;
    });
  },
  // eslint-disable-next-line no-unused-vars
  getCategory({ commit }, data) {
    return Service.media.getCategory(data).then((response) => {
      commit("SET_CATEGORIES", response.group_name);
      commit("SET_ARTICLE", response.data);
      commit("SET_PAGINATE", {
        page: response.current_page,
        itemsPerPage: response.per_page,
        pageStart: response.from,
        pageStop: response.last_page,
        pageCount: Math.ceil(response.total / response.per_page),
      });
      return response;
    });
  },
};
const mutations = {
  SET_CATEGORIES: (state, data) => {
    state.categories = data;
  },
  SET_PAGINATE: (state, data) => {
    state.paginate = data;
  },
  SET_RENDER: (state, data) => {
    state.isRender = data;
  },
  SET_ARTICLE: (state, data) => {
    state.article = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
