<template>
  <div class="text-center mt-18">
    <div class="font-40 mb-6">Page not found!</div>
    <v-btn to="/" color="#C71010" outlined>
      <div>Go to home page</div>
    </v-btn>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
