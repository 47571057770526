import Service from '../../services/index'

const state = {
    list: [],
}

const getters = {}
const actions = {
    // eslint-disable-next-line no-unused-vars
    get({ commit }) {
        return Service.banner.get().then((response) => {
            commit("SET_LIST", response);
            return response;
        })
    },
}
const mutations = {
    SET_LIST: (state, data) => {
        state.list = data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}


