<template>
  <v-container>
    <div class="font-24 mb-2" style="color: #bcc4cb">Create Case</div>
    <v-layout justify-space-between align-center>
      <v-flex shrink class="header_title"> ปรึกษาเคส </v-flex>
      <v-flex shrink class="font-18 font-w-500" style="color: #bcc4cb">
        รหัส Case: <span style="color: #6e49ec">{{ form.room_code }}</span>
      </v-flex>
    </v-layout>

    <!-- form -->
    <v-form
      :style="!isPreview ? { display: 'block' } : { display: 'none' }"
      ref="form"
      class="case_form px-2 px-sm-6 px-md-16 mt-8 mt-sm-0"
      v-model="valid"
      lazy-validation
    >
      <div class="font-24 mb-2 font-w-500 txt_color_case">
        <span style="color: #ff7070">*</span>กลุ่มปัญหา
      </div>
      <v-layout class="mt-6" wrap>
        <v-flex xs12 sm4 md3 class="mb-3 mb-sm-0 pr-0 pr-sm-3">
          <div class="checkbox_form">
            <v-checkbox
              background-color="#fff"
              color="#6e49ec"
              :rules="[validCheck || 'กรุณาเลือก *กลุ่มปัญหา']"
              v-model="form.issue_group"
              label="ความรุนแรงต่อเด็ก"
              value="ความรุนแรงต่อเด็ก"
            ></v-checkbox>
          </div>
        </v-flex>
        <v-flex xs12 sm4 md3 class="mb-3 mb-sm-0 pr-0 pr-sm-3">
          <div class="checkbox_form">
            <v-checkbox
              background-color="#fff"
              color="#6e49ec"
              :rules="[validCheck || 'กรุณาเลือก *กลุ่มปัญหา']"
              v-model="form.issue_group"
              label="ความรุนแรงในครอบครัว"
              value="ความรุนแรงในครอบครัว"
            ></v-checkbox>
          </div>
        </v-flex>
        <v-flex xs12 sm4 md3 class="mb-3 mb-sm-0">
          <div class="checkbox_form">
            <v-checkbox
              background-color="#fff"
              color="#6e49ec"
              :rules="[validCheck || 'กรุณาเลือก *กลุ่มปัญหา']"
              v-model="form.issue_group"
              label="ปัญหาสุขภาพจิต"
              value="ปัญหาสุขภาพจิต"
            ></v-checkbox>
          </div>
        </v-flex>
      </v-layout>

      <v-layout class="mt-0 mt-sm-6" align-center wrap>
        <v-flex xs12 sm4 md3 class="mb-3 mb-sm-0 pr-0 pr-sm-3">
          <div class="checkbox_form">
            <v-checkbox
              color="#6e49ec"
              :rules="[validCheck || 'กรุณาเลือก *กลุ่มปัญหา']"
              v-model="form.issue_other"
              label="อื่นๆ"
              background-color="#fff"
              value="อื่นๆ"
            ></v-checkbox>
          </div>
        </v-flex>
        <v-flex v-if="form.issue_other" xs12 sm8 md6>
          <v-text-field
            background-color="#fff"
            outlined
            dense
            color="#6e49ec"
            hide-details
            :rules="[validCheckOther || 'ปัญหาสุขภาพจิต is required']"
            v-model="form.issue_other_text"
            placeholder="ระบุกลุ่มปัญหา"
            value="3"
          ></v-text-field>
        </v-flex>
      </v-layout>

      <v-layout wrap class="mt-12" align-center>
        <v-flex class="font-24 font-w-500 txt_color_case" xs12>
          ข้อมูลเคส
          <span class="font-18" style="color: black"
            >(อายุและเพศระบุผู้ที่เป็นเคสหลัก)</span
          >
        </v-flex>
        <v-flex class="font-18 font-w-500 txt_color_case" xs12> </v-flex>
        <v-flex xs12 sm8 md6 class="pr-0 pr-sm-3">
          <v-layout wrap>
            <v-flex class="font-18" xs12> เพศ </v-flex>
            <v-flex class="">
              <v-radio-group color="#6e49ec" v-model="form.gender" row>
                <v-layout justify-space-between>
                  <v-flex
                    v-for="(item, i) in genders"
                    :key="i"
                    class="pr-1 pr-sm-3"
                  >
                    <div class="checkbox_form py-3">
                      <v-radio
                        color="#6e49ec"
                        :label="item.text"
                        :value="item.id"
                      >
                      </v-radio>
                    </div>
                  </v-flex>
                </v-layout>
              </v-radio-group>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 sm4 md6 class="pl-3">
          <v-layout wrap>
            <v-flex class="font-18 mb-2" xs12> อายุ (ปี) </v-flex>
            <v-flex class="">
              <v-text-field
                outlined
                dense
                background-color="#fff"
                color="#6e49ec"
                hide-details
                v-model="form.age"
                placeholder="ระบุอายุ"
                value="3"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <div class="mt-8 mt-sm-4">
        <label class="font-24 font-w-500 txt_color_case">
          <span style="color: #ff7070">*</span
          >รายละเอียดเหตุการณ์/ลักษณะครอบครัว (โดยสังเขป)
        </label>
        <v-textarea
          background-color="#fff"
          :rules="[(v) => !!v || 'ระบุรายละเอียด']"
          outlined
          placeholder="ระบุรายละเอียด"
          v-model="form.description"
        />
      </div>

      <div class="mt-4">
        <label class="font-24 font-w-500 txt_color_case">
          <span style="color: #ff7070">*</span>ปัญหา/สิ่งที่ต้องการปรึกษา
        </label>
        <v-textarea
          background-color="#fff"
          :rules="[(v) => !!v || 'ระบุปัญหา/สิ่งที่ต้องการปรึกษ']"
          outlined
          placeholder="ระบุรายละเอียด"
          v-model="form.issue_detail"
        />
      </div>

      <div class="mt-4">
        <div class="txt_color_case font-24 font-w-500">รูปภาพประกอบ</div>

        <v-layout mt-2 wrap justify-center>
          <v-flex :class="$vuetify.breakpoint.xs ? 'xs12' : 'shrink'" mb-n2>
            <img
              class="image_preview"
              id="image"
              src="../../../assets/images/default-image.png"
            />
          </v-flex>
          <v-flex class="mx-0 mx-sm-4 mt-3 mt-sm-0 pr-3 pr-sm-0 pt-5">
            <input
              id="fileInput"
              ref="fileInput"
              class="d-none"
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              @input="onUploadImage"
            />
            <v-file-input
              placeholder="รองรับไฟล์ .jpg .jpeg หรือ .png ที่ขนาดไฟล์ไม่เกิน 5 MB"
              show-size
              outlined
              dense
              ref="fileInput"
              prepend-icon=""
              background-color="#fff"
              v-model="imageName"
              accept="image/png, image/jpg, image/jpeg"
              :rules="rules"
              @change="onUploadImage"
              clearable
              @click:clear="clearInput"
            ></v-file-input>
          </v-flex>
          <v-flex shrink class="pt-5">
            <v-btn @click="onTriggleFileInput" color="#6e49ec" depressed dark>
              อัปโหลด
            </v-btn>
          </v-flex>
        </v-layout>
      </div>

      <div class="my-16 d-flex justify-lg-space-between">
        <v-btn
          style="border-radius: 30px"
          min-width="165px"
          depressed
          color="#BCC4CB"
          outlined
          min-height="58"
          @click="$router.go(-1)"
        >
          ยกเลิก
        </v-btn>
        <v-spacer />
        <v-btn
          style="border-radius: 30px"
          min-width="165px"
          depressed
          :disabled="!valid"
          color="#4090FF"
          :dark="valid"
          min-height="58"
          @click="validate"
        >
          ยืนยัน
        </v-btn>
      </div>
    </v-form>

    <!-- preview form  -->
    <div
      :style="isPreview ? { display: 'block' } : { display: 'none' }"
      class="px-2 px-sm-6 px-md-16 mt-12"
    >
      <div>
        <div class="title__text">กลุ่มปัญหา</div>
        <div class="desc__text">
          {{ issue_group }}
        </div>
      </div>

      <v-divider class="my-6" />

      <div>
        <div class="title__text mb-4">ข้อมูลผู้ให้บริการ</div>
        <v-layout>
          <v-flex>
            <div style="color: #bcc4cb" class="font-18">เพศ</div>
            <div class="desc__text">{{ gender }}</div>
          </v-flex>
          <v-flex>
            <div style="color: #bcc4cb" class="font-18">อายุ (ปี)</div>
            <div class="desc__text">
              {{ form.age }}
            </div>
          </v-flex>
        </v-layout>
      </div>

      <div class="my-7">
        <div class="title__text">รายละเอียด/ลักษณะครอบครัว (โดยสังเขป)</div>
        <div class="desc__text">{{ form.description }}</div>
      </div>

      <div class="my-7">
        <div class="title__text">ปัญหา/ความต้องการ</div>
        <div class="desc__text">{{ form.issue_detail }}</div>
      </div>

      <div class="my-7">
        <div>รูปภาพประกอบ</div>

        <v-layout align-end mt-2>
          <v-flex :class="$vuetify.breakpoint.xs ? 'xs12' : 'shrink'" mb-n2>
            <img
              class="image_preview"
              id="image_preview"
              src="../../../assets/images/default-image.png"
            />
          </v-flex>
        </v-layout>
      </div>

      <v-divider class="my-12" />

      <v-layout justify-space-between wrap class="my-12 d-flex">
        <v-flex xs12 sm6 class="order-1 order-sm-0">
          <v-btn
            :block="$vuetify.breakpoint.xs"
            style="border-radius: 30px"
            min-width="165px"
            depressed
            color="#BCC4CB"
            outlined
            height="58"
            @click="isPreview = false"
          >
            <span class="font-18 font-w-500"> ยกเลิก</span>
          </v-btn>
        </v-flex>

        <v-flex
          xs12
          sm6
          class="order-0 order-sm-1 mb-4 mb-sm-0 d-flex justify-end"
        >
          <v-btn
            :block="$vuetify.breakpoint.xs"
            style="border-radius: 30px"
            min-width="100px"
            depressed
            color="#6E49EC"
            @click="save"
            dark
            height="58"
            :loading="loadingBtn"
          >
            <span class="font-18 font-w-500"> ยืนยันการสร้าง Case</span>
          </v-btn>
        </v-flex>
      </v-layout>
    </div>
    <Loading :loading="isLoading"></Loading>
  </v-container>
</template>

<script>
import SERVICE from "../../../services/index";
import Loading from "../../../components/Loading.vue";

export default {
  data() {
    return {
      isLoading: false,
      isPreview: false,
      loadingBtn: false,
      genders: [
        { id: "M", text: "ชาย" },
        { id: "F", text: "หญิง" },
        { id: "LGBTQ", text: "เพศทางเลือก" },
      ],
      valid: true,
      user_id: null,
      form: {
        room_code: "",
        issue_group: [],
        issue_other: "",
        issue_other_text: "",
        gender: "",
        age: "",
        description: "",
        issue_detail: "",
        image: null,
      },
      imageName: null,
      rules: [
        (value) =>
          !value || value.size < 5000000 || "ขนาดไฟล์ต้องไม่เกิน 5 MB!",
      ],
    };
  },
  watch: {
    form: {
      handler(newValue, oldValue) {
        if (!newValue.issue_other) {
          this.form.issue_other_text = "";
        }
      },
      deep: true,
    },
  },
  computed: {
    issue_other() {
      return this.form.issue_other ? this.form.issue_other_text : "";
    },
    gender() {
      switch (this.form.gender) {
        case "M":
          return "ชาย";
        case "F":
          return "หญิง";
        default:
          return "เพศทางเลือก";
      }
    },
    issue_group() {
      return (
        this.form.issue_group.toString() +
        (this.issue_other
          ? (this.form.issue_group.length > 0 ? "," : "") + this.issue_other
          : "")
      );
    },
    validCheck() {
      if (this.form.issue_group.length === 0 && !this.form.issue_other) {
        return false;
      } else {
        return true;
      }
    },
    validCheckOther() {
      if (this.form.issue_other && !this.form.issue_other_text) {
        return false;
      } else {
        return true;
      }
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    this.generateRoomCode();
  },
  methods: {
    clearInput() {
      // clear input value by id imge_preview and image
      document.getElementById("image").src =
        "https://oscc-chat-api.ksta.co/img/default-image.png";
      document.getElementById("image_preview").src =
        "https://oscc-chat-api.ksta.co/img/default-image.png";
      this.form.image = null;
      this.form.image_name = null;
    },
    onTriggleFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.isPreview = true;
      }
    },
    generateRoomCode() {
      this.isLoading = true;
      SERVICE.chat
        .generateRoomCode(this.form)
        .then((res) => {
          this.isLoading = false;
          this.form.room_code = res.data;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    save() {
      this.loadingBtn = true;
      this.form["user_id"] = this.user.id;
      this.form["roomName"] = this.issue_group;
      SERVICE.chat
        .createCase(this.form)
        .then((res) => {
          this.loadingBtn = false;
          this.$swal({
            title: "สร้างเคสเรียบร้อยแล้ว",
            icon: "success",
            confirmButtonText: "ตกลง",
          }).then(() => {
            this.$router.push("/chat");
          });
        })
        .catch((error) => {
          this.loadingBtn = false;
        });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async onUploadImage(e) {
      // set image
      this.form.image_name = e.name;
      this.form.image = await this.getBase64(e); // convert file to base64
      // preview image
      var output = document.getElementById("image");
      output.src = URL.createObjectURL(e);
      output.onload = function () {
        URL.revokeObjectURL(output.src); // free memory
      };
      var preview = document.getElementById("image_preview");
      preview.src = URL.createObjectURL(e);
      preview.onload = function () {
        URL.revokeObjectURL(preview.src); // free memory
      };
    },
    getBase64(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    },
    clearImage() {
      this.form.image = "";
    },
  },
  components: { Loading },
};
</script>

<style lang="scss">
.case_form {
  .v-input--selection-controls {
    margin-top: 8px !important;
    padding-top: 4px;
  }
  .v-input__slot {
    margin-bottom: 0px !important;
  }
  .v-label {
    font-size: 12px !important;
  }
  .v-messages {
    font-size: 11px !important;
    // padding-left: 30px !important;
  }
  .v-messages__message {
    margin-top: 5px !important;
  }
}
.header_title {
  font-family: "Prompt";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  color: #5e616f;
  border-left: 4px solid #4090ff;
  padding-left: 12px;
}
.checkbox_form {
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d3d9e3;
  box-sizing: border-box;
  border-radius: 25px;
  padding: 0px 10px;
  // margin-right: 10px;
  //   width: 200px;
}
.image_preview {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  width: 81px;
  height: 81px;
  object-fit: cover;
}
.v-input--selection-controls__input {
  .v-icon.v-icon {
    font-size: 30px;
    color: #bcc4cb;
  }
}

@media only screen and (max-width: 600px) {
  .image_preview {
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.title__text {
  font-family: "Prompt";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #6e49ec;
}
.desc__text {
  font-family: "Prompt";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #5e616f;
}
.txt_color_case {
  color: #6e49ec;
}
</style>
