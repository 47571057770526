<template>
  <div class="text-center">
    <v-card class="px-6 py-10" style="border-radius: 30px">
      <div class="text-center mb-8 font-24 font-w-500">แบบสอบถาม</div>

      <v-layout justify-center class="">
        <v-flex xs10>
          <v-layout class="px-6 mb-8" align-center>
            <v-flex shrink mr-2>
              <v-img
                width="50"
                aspect-ratio="1"
                src="../assets/icon/document-text.png"
              >
              </v-img>
            </v-flex>
            <v-flex class="font-14 font-w-500">
              ท่านสะดวกทำแบบสอบถามเพื่อพัฒนาระบบหรือไม่
            </v-flex>
          </v-layout>

          <v-layout justify-space-between>
            <v-flex shrink>
              <v-btn
                min-width="110"
                rounded
                dark
                color="#BCC4CB"
                depressed
                @click="$emit('setDialog', 'reject')"
              >
                ไม่ยินยอม
              </v-btn>
            </v-flex>
            <v-flex shrink>
              <v-btn
                min-width="110"
                rounded
                class="btn-blue-gradient"
                depressed
                @click="onApprove()"
              >
                ยินยอม
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    isLogout: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    access_token() {
      return this.$store.state.auth.access_token;
    },
    questionnaire() {
      return this.$store.state.auth.questionnaire;
    },
  },
  methods: {
    onApprove() {
      // this.$store.commit("auth/set_questionnaire");
      this.$emit("setDialog", "approve");

      // todo change link google form
      window.open("https://docs.google.com/forms/u/0/?tgif=d");
    },
  },
};
</script>

<style></style>
