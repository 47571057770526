<template>
  <v-hover v-slot="{ hover }">
    <div class="item__card__course px-md-2 px-sm-2 py-md-0 py-sm-2 pa-2">
      <Badge
        :isFix="true"
        text="Upcoming!"
        color="#009982"
        :height="32"
        :width="100"
      ></Badge>
      <v-layout
        wrap
        @click="goto(model.id)"
        class="pointer"
        justify-center
        align-center
        style="color: #5e616f; height: 100%"
      >
        <v-flex xs12 sm4 md6 lg6 style="position: relative">
          <v-img
            v-if="image"
            height="100%"
            :aspect-ratio="16 / 9"
            :class="hover ? 'img-hover' : ''"
            :src="storage_path + image"
          ></v-img>
          <v-img
            v-else
            height="100%"
            :aspect-ratio="16 / 9"
            :class="hover ? 'img-hover' : ''"
            src="../../assets/image/no-img-course.png"
          ></v-img>
          <ReadMore v-if="hover"></ReadMore>
        </v-flex>
        <v-flex
          xs12
          sm8
          md6
          lg6
          class="d-flex flex-column justify-center"
          style="padding: 6px"
        >
          <div class="overflow-ellipsis-2 font-16">{{ topic }}</div>

          <div
            v-html="content"
            class="overflow-ellipsis-3 course-sm-panel font-12 my-2"
          ></div>

          <v-layout align-center>
            <v-flex
              shrink
              class="mr-4 mr-md-0 mr-lg-1 d-flex align-center font-12"
            >
              <v-icon class="mr-1" small color="#4090FF"
                >mdi-calendar-text</v-icon
              >
              {{
                changeDate(
                  model.open_date_courses,
                  model.open_time_courses,
                  model.end_time_courses
                )
              }}
            </v-flex>

            <v-flex shrink class="d-flex align-center font-13">
              <v-icon class="mr-1" small color="#80C843"
                >mdi-account-plus</v-icon
              >
              {{
                model.user_course_normal_count
                  ? model.user_course_normal_count
                  : 0
              }}/{{ model.number_user }}
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </v-hover>
</template>

<script>
import Badge from "../Badge.vue";
import ReadMore from "../ReadMoreHover.vue";
import { changeDate } from "../../plugins/date-time-convert";
import { storage_path } from "../../util/Constant";
export default {
  props: ["topic", "content", "date", "image", "number", "model"],
  components: {
    Badge,
    ReadMore,
  },
  data() {
    return {
      storage_path: storage_path,
      changeDate: changeDate,
    };
  },
  methods: {
    goto(id) {
      this.$router.push(`/course/${id}`);
    },
  },
};
</script>

<style scoped>
.emblem__upcoming {
  background: linear-gradient(94.59deg, #80c843 7.71%, #009982 95.17%);
  border-radius: 5px;
}

div >>> p {
  font-size: 12px !important;
  font-family: Prompt !important;
}
div >>> span {
  font-size: 12px !important;
  font-family: Prompt !important;
}
</style>
