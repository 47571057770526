<template>
  <div>
    <Loading :loading="isLoading"></Loading>
    <v-row>
      <v-col
        class="my-2 px-2 py-0"
        cols="12"
        md="4"
        v-for="(item, i) in category"
        :key="i"
      >
        <MediaMenu :id="i" :name="item"></MediaMenu>
      </v-col>
    </v-row>
    <div v-if="!isPaginate" class="my-10">
      <carousel
        v-if="article.length > 0 && !isRender"
        :margin="2"
        :stagePadding="$vuetify.breakpoint.mdAndUp ? 0 : 40"
        :items="$vuetify.breakpoint.mdAndUp ? 4 : 1"
        :nav="false"
      >
        <MediaPanel
          v-for="(item, i) in article"
          :key="i"
          :image="item.image"
          :date="item.release_date"
          :topic="item.topic"
          :id="item.id"
        ></MediaPanel>
      </carousel>
      <div v-else>
        <h1 class="text-center">ไม่พบสื่อความรู้</h1>
      </div>
    </div>
    <v-row v-else>
      <v-col
        style="padding-left: 1px !important; padding-right: 1px !important"
        cols="12"
        md="4"
        lg="3"
        v-for="(item, i) in article"
        :key="i"
      >
        <MediaPanel
          :image="item.image"
          :date="item.release_date"
          :topic="item.topic"
          :id="item.id"
        ></MediaPanel>
      </v-col>
      <v-col cols="12">
        <v-pagination
          class="my-10"
          v-model="paginate.page"
          :length="paginate.pageCount"
          circle
          @input="changePage"
        ></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Loading from "../Loading.vue";
import carousel from "vue-owl-carousel";
import MediaMenu from "./MediaMenu.vue";
import MediaPanel from "./MediaPanel.vue";
import { mapState } from "vuex";
export default {
  props: ["isPaginate"],
  components: {
    MediaMenu,
    MediaPanel,
    Loading,
    carousel,
  },
  computed: {
    ...mapState({
      category: (state) => state.Media.categories,
      article: (state) => state.Media.article,
      paginate: (state) => state.Media.paginate,
      isRender: (state) => state.Media.isRender,
    }),
  },
  async created() {
    await this.$store.dispatch("Media/getCategory", {
      paginate: this.isPaginate ? 8 : 4,
    });
    this.$emit("loadingDone");
  },
  methods: {
    async changePage(val) {
      this.isLoading = true;
      await this.$store.dispatch("Media/getCategory", { 
        page: val,
        paginate: 8,
      });
      this.isLoading = false;
    },
  },
  data() {
    return {
      isLoading: false,
      mediaArticle: [],
      mediaList: [
        "ความรุนแรงต่อเด็ก",
        "ความรุนแรงในครอบครัว",
        "ปัญหาสุขภาพจิต",
        "เด็กและครอบครัว",
        "กรณีศึกษา",
        "อื่นๆ",
      ],
    };
  },
};
</script>

<style>
.owl-prev {
  width: 15px;
  height: 100px;
  position: absolute;
  top: 40%;
  margin-left: -20px;
  display: block !important;
  border: 0px solid black;
}

.owl-next {
  width: 15px;
  height: 100px;
  position: absolute;
  top: 40%;
  right: -25px;
  display: block !important;
  border: 0px solid black;
}
.owl-prev i,
.owl-next i {
  transform: scale(1, 6);
  color: #ccc;
}
</style>
