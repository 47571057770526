<template>
  <div class="mental">
    <v-container class="pb-12">
      <v-layout align-end>
        <v-flex xs9>
          <Topic
            :thai="
              topic.title_th === 'ทั้งหมด'
                ? 'สำหรับผู้ให้บริการ'
                : topic.title_th
            "
            :eng="topic.title_en"
          />
        </v-flex>
        <v-flex xs3>
          <div v-if="user.roles.role_id == 1 || user.roles.role_id == 6">
            <div>ตารางวันว่างของ</div>
            <v-autocomplete
              clearable
              v-model="filter"
              outlined
              dense
              :items="mentors"
              hide-details
              item-text="full_name"
              item-value="id"
              placeholder="ที่ปรึกษา"
              @change="getMentors"
              @click:clear="clearFilter()"
            ></v-autocomplete>
          </div>
        </v-flex>
      </v-layout>

      <!-- Tab -->
      <div class="tab mt-8">
        <v-layout justify-space-between align-center wrap>
          <v-flex xs12 sm12 md10 lg8 class="ml-n12 ml-sm-n3">
            <v-tabs v-model="tab" background-color="transparent">
              <v-tab
                v-for="(item, i) in tabs_consultant"
                :key="i"
                @click="onClickTab(item)"
              >
                <span style="color: #5e616f" class="font-18 font-w-500">
                  {{ item.title_th }}
                </span>
              </v-tab>
            </v-tabs>
          </v-flex>
          <v-flex
            xs12
            sm12
            md2
            lg4
            class="d-flex justify-md-end justify-start mt-4 mt-md-4"
            v-if="
              (user.roles.role_id == 1 ||
                user.roles.role_id == 3 ||
                user.roles.role_id == 5) &&
              tab === 3
            "
          >
            <v-btn
              @click="dialog = true"
              style="border-radius: 30px"
              color="#6E49EC"
              dark
            >
              <v-img
                src="../../../assets/icon/date-chat-icon-white.png"
              ></v-img>
              <span class="ml-2">ตั้งค่าวัน-เวลาว่าง</span>
            </v-btn>
          </v-flex>
        </v-layout>

        <div class="tab_item mt-8">
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="(item, i) in tabs_consultant" :key="i">
              <!-- Tab 1 -->

              <div v-if="tab === 0">
                <div class="tab_1 tab_list" v-if="cases.length > 0">
                  <div v-for="(item, i) in cases" :key="i">
                    <v-layout
                      wrap
                      v-if="item.type"
                      class="card_item px-4 py-3"
                      :class="i + 1 !== cases.length ? 'mb-3' : ''"
                    >
                      <!-- Icon -->
                      <v-flex xs12 sm1 class="pr-6">
                        <v-img
                          v-if="item.type === 'ปรึกษาปัญหาส่วนตัว'"
                          width="40"
                          height="40"
                          aspect-ratio="1"
                          src="../../../assets/images/personal-appointment/icon-personal-appointment.png"
                        ></v-img>
                        <v-img
                          v-else
                          width="40"
                          aspect-ratio="1"
                          src="../../../assets/images/personal-appointment/icon-case.png"
                        ></v-img>
                      </v-flex>

                      <v-flex xs12 sm11>
                        <v-layout align-center>
                          <v-flex
                            shrink
                            class="txt_gray font-18 font-w-500"
                            v-if="$vuetify.breakpoint.smAndUp"
                          >
                            Link Zoom:
                          </v-flex>
                          <v-flex
                            shrink
                            class="px-0 px-sm-3 font-14 font-w-400 ell-1"
                          >
                            <span
                              class="txt_blue"
                              v-if="item.type === 'ปรึกษาปัญหาส่วนตัว'"
                            >
                              <a
                                v-if="item.zoom_url"
                                :href="item.zoom_url"
                                target="_blank"
                              >
                                <span class="txt_blue">
                                  {{ item.zoom_url }}
                                </span>
                              </a>
                              <span v-else class="txt_blue">
                                ยังไม่มีลิ้งค์
                              </span>
                            </span>
                            <span class="txt_purple" v-else>
                              <a
                                v-if="
                                  item.appointment_chat &&
                                  item.appointment_chat.zoom_url
                                "
                                :href="item.appointment_chat.zoom_url"
                                target="_blank"
                              >
                                <span class="txt_blue">
                                  {{ item.appointment_chat.zoom_url }}
                                </span>
                              </a>
                              <span v-else class="txt_blue">
                                ยังไม่มีลิ้งค์
                              </span>
                            </span>
                          </v-flex>
                          <v-flex
                            shrink
                            v-if="item.type === 'ปรึกษาปัญหาส่วนตัว'"
                          >
                            <v-btn
                              depressed
                              dark
                              color="#5E616F"
                              max-height="22"
                              v-if="item.zoom_url"
                              @click="copy(item.zoom_url)"
                            >
                              คัดลอก
                            </v-btn>
                          </v-flex>
                          <v-flex shrink v-else>
                            <v-btn
                              depressed
                              dark
                              color="#5E616F"
                              max-height="22"
                              v-if="
                                item.appointment_chat &&
                                item.appointment_chat.zoom_url
                              "
                              @click="copy(item.appointment_chat.zoom_url)"
                            >
                              คัดลอก
                            </v-btn>
                          </v-flex>
                        </v-layout>

                        <v-layout class="mt-3" wrap>
                          <v-flex sm3 xs6>
                            <div class="txt_gray font-18 font-w-500">
                              ประเภท
                            </div>
                            <div
                              v-if="item.type === 'ปรึกษาปัญหาส่วนตัว'"
                              class="font-14 font-w-400"
                            >
                              {{ item.type }}
                            </div>
                            <div v-else class="font-14 font-w-400">
                              {{
                                item.appointment_chat &&
                                item.appointment_chat.type
                                  ? item.appointment_chat.type
                                  : ""
                              }}
                            </div>
                          </v-flex>

                          <v-flex sm3 xs6 class="mb-3 mb-sm-0">
                            <div class="txt_gray font-18 font-w-500">
                              วันเวลา
                            </div>

                            <div
                              class="font-14 font-w-400"
                              v-if="item.type === 'ปรึกษาปัญหาส่วนตัว'"
                            >
                              {{ item.date }}
                            </div>

                            <div v-else class="font-14 font-w-400">
                              {{
                                item.appointment_chat &&
                                item.appointment_chat.date
                                  ? item.appointment_chat.date
                                  : ""
                              }}
                            </div>
                            <div class="font-14 font-w-400">
                              {{ checkTime(item) }}
                            </div>
                          </v-flex>

                          <v-flex sm3 xs6>
                            <div class="txt_gray font-18 font-w-500">
                              ชื่อสมาชิก
                            </div>

                            <div
                              v-if="item.type === 'ปรึกษาปัญหาส่วนตัว'"
                              class="font-14 font-w-400"
                            >
                              <div>
                                {{ checkNameStaff(item) }}
                              </div>
                              <div>
                                {{ checkNamePosition(item) }}
                              </div>
                            </div>

                            <div v-else class="font-14 font-w-400">
                              <div>
                                {{ checkNameStaff(item.appointment_chat) }}
                              </div>
                              <div>
                                {{ checkNamePosition(item.appointment_chat) }}
                              </div>
                            </div>
                          </v-flex>

                          <!-- สถานะ -->
                          <v-flex sm3 xs6>
                            <div class="txt_gray font-18 font-w-500">สถานะ</div>

                            <!-- ปรึกษาปัญหาส่วนตัว -->
                            <div
                              class="font-14 font-w-400"
                              v-if="item.type === 'ปรึกษาปัญหาส่วนตัว'"
                            >
                              <div v-if="item.zoom_url">
                                <div
                                  v-if="
                                    checkStatusDate(item) === 'ถึงเวลานัดหมาย'
                                  "
                                >
                                  <span class="font-14 font-w-400 mr-2">
                                    ถึงเวลานัดหมาย
                                  </span>
                                  <v-icon color="#80c843" small>
                                    mdi-checkbox-blank-circle
                                  </v-icon>
                                </div>
                                <div
                                  v-else-if="
                                    checkStatusDate(item) === 'ใกล้เวลานัดหมาย'
                                  "
                                >
                                  <span class="font-14 font-w-400 mr-2">
                                    ใกล้เวลานัดหมาย
                                  </span>
                                  <v-icon color="#FFE0E0" small>
                                    mdi-checkbox-blank-circle
                                  </v-icon>
                                </div>

                                <div
                                  v-else-if="
                                    checkStatusDate(item) === 'หมดเวลานัดหมาย'
                                  "
                                >
                                  <span class="font-14 font-w-400 mr-2">
                                    หมดเวลานัดหมาย
                                  </span>
                                  <v-icon color="red" small>
                                    mdi-checkbox-blank-circle
                                  </v-icon>
                                </div>

                                <div v-else>
                                  <span class="font-14 font-w-400 mr-2">
                                    รอการนัดหมาย
                                  </span>
                                  <v-icon color="#4090ff" small>
                                    mdi-checkbox-blank-circle
                                  </v-icon>
                                </div>
                              </div>
                              <div v-else>
                                <span class="font-14 font-w-400 mr-2">
                                  นัดหมายใหม่
                                </span>
                                <span
                                  style="
                                    background-color: #f6c517;
                                    border-radius: 5px;
                                    color: #fff;
                                  "
                                  class="font-12 font-w-500 pl-1 pr-2"
                                >
                                  ใหม่!
                                </span>
                              </div>
                            </div>

                            <!-- ปรึกษาปัญหาเคส -->
                            <div class="font-14 font-w-400" v-else>
                              <div
                                v-if="
                                  item.appointment_chat &&
                                  item.appointment_chat.zoom_url
                                "
                              >
                                <div
                                  v-if="
                                    checkStatusDate(item.appointment_chat) ===
                                    'ถึงเวลานัดหมาย'
                                  "
                                >
                                  <span class="font-14 font-w-400 mr-2">
                                    ถึงเวลานัดหมาย
                                  </span>
                                  <v-icon color="#80c843" small>
                                    mdi-checkbox-blank-circle
                                  </v-icon>
                                </div>
                                <div
                                  v-else-if="
                                    checkStatusDate(item.appointment_chat) ===
                                    'ใกล้เวลานัดหมาย'
                                  "
                                >
                                  <span class="font-14 font-w-400 mr-2">
                                    ใกล้เวลานัดหมาย
                                  </span>
                                  <v-icon color="#FFE0E0" small>
                                    mdi-checkbox-blank-circle
                                  </v-icon>
                                </div>

                                <div
                                  v-else-if="
                                    checkStatusDate(item.appointment_chat) ===
                                    'หมดเวลานัดหมาย'
                                  "
                                >
                                  <span class="font-14 font-w-400 mr-2">
                                    หมดเวลานัดหมาย
                                  </span>
                                  <v-icon color="red" small>
                                    mdi-checkbox-blank-circle
                                  </v-icon>
                                </div>

                                <div v-else>
                                  <span class="font-14 font-w-400 mr-2">
                                    รอการนัดหมาย
                                  </span>
                                  <v-icon color="#4090ff" small>
                                    mdi-checkbox-blank-circle
                                  </v-icon>
                                </div>
                              </div>
                              <div v-else>
                                <span class="font-14 font-w-400 mr-2">
                                  นัดหมายใหม่
                                </span>
                                <span
                                  style="
                                    background-color: #f6c517;
                                    border-radius: 5px;
                                    color: #fff;
                                  "
                                  class="font-12 font-w-500 pl-1 pr-2"
                                >
                                  ใหม่!
                                </span>
                              </div>
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </div>
                </div>
                <div v-else class="mt-6 text-center">ไม่พบข้อมูล</div>
              </div>

              <!-- Tab 2 -->
              <div class="" v-if="tab === 1">
                <div class="tab_2 tab_list" v-if="appointmentCases.length > 0">
                  <div
                    class="card_item px-4 py-3 mb-3"
                    v-for="(item, i) in appointmentCases"
                    :key="i"
                  >
                    <v-layout wrap>
                      <!-- Icon -->
                      <v-flex xs12 sm1 class="pr-6">
                        <v-img
                          width="40"
                          aspect-ratio="1"
                          src="../../../assets/images/personal-appointment/icon-case.png"
                        ></v-img>
                      </v-flex>

                      <v-flex xs12 sm11>
                        <v-layout align-center>
                          <v-flex
                            shrink
                            class="txt_gray font-18 font-w-500"
                            v-if="$vuetify.breakpoint.smAndUp"
                          >
                            Link Zoom:
                          </v-flex>
                          <v-flex
                            shrink
                            class="px-0 px-sm-3 font-14 font-w-400 ell-1"
                          >
                            <a
                              v-if="
                                item.appointment_chat &&
                                item.appointment_chat.zoom_url
                              "
                              :href="item.appointment_chat.zoom_url"
                              target="_blank"
                            >
                              <span class="txt_purple">
                                {{ item.appointment_chat.zoom_url }}
                              </span>
                            </a>
                            <span v-else class="txt_purple">
                              ยังไม่มีลิ้งค์
                            </span>
                          </v-flex>

                          <v-flex shrink>
                            <v-btn
                              depressed
                              dark
                              color="#5E616F"
                              max-height="22"
                              v-if="
                                item.appointment_chat &&
                                item.appointment_chat.zoom_url
                              "
                              @click="copy(item.appointment_chat.zoom_url)"
                            >
                              คัดลอก
                            </v-btn>
                          </v-flex>
                        </v-layout>

                        <v-layout class="mt-3" wrap>
                          <v-flex sm3 xs6>
                            <div class="txt_gray font-18 font-w-500">
                              ประเภท
                            </div>

                            <div class="font-14 font-w-400">
                              {{
                                item.appointment_chat &&
                                item.appointment_chat.type
                                  ? item.appointment_chat.type
                                  : ""
                              }}
                            </div>
                          </v-flex>

                          <v-flex sm3 xs6 class="mb-3 mb-sm-0">
                            <div class="txt_gray font-18 font-w-500">
                              วันเวลา
                            </div>
                            <div class="font-14 font-w-400">
                              {{
                                item.appointment_chat &&
                                item.appointment_chat.date
                                  ? item.appointment_chat.date
                                  : ""
                              }}
                            </div>
                            <div class="font-14 font-w-400">
                              {{ checkTime(item) }}
                            </div>
                          </v-flex>

                          <v-flex sm3 xs6>
                            <div class="txt_gray font-18 font-w-500">
                              ชื่อสมาชิก
                            </div>

                            <div class="font-14 font-w-400">
                              <div>
                                {{ checkNameStaff(item.appointment_chat) }}
                              </div>
                              <div>
                                {{ checkNamePosition(item.appointment_chat) }}
                              </div>
                            </div>
                          </v-flex>

                          <!-- สถานะ -->
                          <v-flex sm3 xs6>
                            <div class="txt_gray font-18 font-w-500">สถานะ</div>

                            <!-- ปรึกษาปัญหาเคส -->
                            <div class="font-14 font-w-400">
                              <div
                                v-if="
                                  item.appointment_chat &&
                                  item.appointment_chat.zoom_url
                                "
                              >
                                <div
                                  v-if="
                                    checkStatusDate(item.appointment_chat) ===
                                    'ถึงเวลานัดหมาย'
                                  "
                                >
                                  <span class="font-14 font-w-400 mr-2">
                                    ถึงเวลานัดหมาย
                                  </span>
                                  <v-icon color="#80c843" small>
                                    mdi-checkbox-blank-circle
                                  </v-icon>
                                </div>
                                <div
                                  v-else-if="
                                    checkStatusDate(item.appointment_chat) ===
                                    'ใกล้เวลานัดหมาย'
                                  "
                                >
                                  <span class="font-14 font-w-400 mr-2">
                                    ใกล้เวลานัดหมาย
                                  </span>
                                  <v-icon color="#FFE0E0" small>
                                    mdi-checkbox-blank-circle
                                  </v-icon>
                                </div>

                                <div
                                  v-else-if="
                                    checkStatusDate(item.appointment_chat) ===
                                    'หมดเวลานัดหมาย'
                                  "
                                >
                                  <span class="font-14 font-w-400 mr-2">
                                    หมดเวลานัดหมาย
                                  </span>
                                  <v-icon color="red" small>
                                    mdi-checkbox-blank-circle
                                  </v-icon>
                                </div>

                                <div v-else>
                                  <span class="font-14 font-w-400 mr-2">
                                    รอการนัดหมาย
                                  </span>
                                  <v-icon color="#4090ff" small>
                                    mdi-checkbox-blank-circle
                                  </v-icon>
                                </div>
                              </div>
                              <div v-else>
                                <span class="font-14 font-w-400 mr-2">
                                  นัดหมายใหม่
                                </span>
                                <span
                                  style="
                                    background-color: #f6c517;
                                    border-radius: 5px;
                                    color: #fff;
                                  "
                                  class="font-12 font-w-500 pl-1 pr-2"
                                >
                                  ใหม่!
                                </span>
                              </div>
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </div>
                </div>
                <div v-else class="mt-6 text-center">ไม่พบข้อมูล</div>
              </div>

              <!-- Tab 3 -->
              <div v-if="tab === 2">
                <div
                  class="tab_3 tab_list"
                  v-if="personalAppointmentCases.length > 0"
                >
                  <div v-for="(item, i) in personalAppointmentCases" :key="i">
                    <v-layout
                      wrap
                      v-if="item.type"
                      class="card_item px-4 py-3 mb-3"
                    >
                      <!-- Icon -->
                      <v-flex xs12 sm1 class="pr-6">
                        <v-img
                          width="40"
                          height="40"
                          aspect-ratio="1"
                          src="../../../assets/images/personal-appointment/icon-personal-appointment.png"
                        ></v-img>
                      </v-flex>

                      <v-flex xs12 sm11>
                        <v-layout align-center>
                          <v-flex
                            shrink
                            class="txt_gray font-18 font-w-500"
                            v-if="$vuetify.breakpoint.smAndUp"
                          >
                            Link Zoom:
                          </v-flex>
                          <v-flex
                            shrink
                            class="px-0 px-sm-3 font-14 font-w-400 ell-1"
                          >
                            <a
                              v-if="item.zoom_url"
                              :href="item.zoom_url"
                              target="_blank"
                            >
                              <span class="txt_blue">
                                {{ item.zoom_url }}
                              </span>
                            </a>
                            <span v-else class="txt_blue">
                              ยังไม่มีลิ้งค์
                            </span>
                          </v-flex>
                          <v-flex shrink>
                            <v-btn
                              depressed
                              dark
                              color="#5E616F"
                              max-height="22"
                              v-if="item.zoom_url"
                              @click="copy(item.zoom_url)"
                            >
                              คัดลอก
                            </v-btn>
                          </v-flex>
                        </v-layout>

                        <v-layout class="mt-3" wrap>
                          <v-flex sm3 xs6>
                            <div class="txt_gray font-18 font-w-500">
                              ประเภท
                            </div>
                            <div class="font-14 font-w-400">
                              {{ item.type }}
                            </div>
                          </v-flex>

                          <v-flex sm3 xs6 class="mb-3 mb-sm-0">
                            <div class="txt_gray font-18 font-w-500">
                              วันเวลา
                            </div>

                            <div class="font-14 font-w-400">
                              {{ item.date }}
                            </div>

                            <div class="font-14 font-w-400">
                              {{ checkTime(item) }}
                            </div>
                          </v-flex>

                          <v-flex sm3 xs6>
                            <div class="txt_gray font-18 font-w-500">
                              ชื่อสมาชิก
                            </div>

                            <div class="font-14 font-w-400">
                              <div>
                                {{ checkNameStaff(item) }}
                              </div>
                              <div>
                                {{ checkNamePosition(item) }}
                              </div>
                            </div>
                          </v-flex>

                          <!-- สถานะ -->
                          <v-flex sm3 xs6>
                            <div class="txt_gray font-18 font-w-500">สถานะ</div>

                            <!-- ปรึกษาปัญหาส่วนตัว -->
                            <div class="font-14 font-w-400">
                              <div v-if="item.zoom_url">
                                <div
                                  v-if="
                                    checkStatusDate(item) === 'ถึงเวลานัดหมาย'
                                  "
                                >
                                  <span class="font-14 font-w-400 mr-2">
                                    ถึงเวลานัดหมาย
                                  </span>
                                  <v-icon color="#80c843" small>
                                    mdi-checkbox-blank-circle
                                  </v-icon>
                                </div>
                                <div
                                  v-else-if="
                                    checkStatusDate(item) === 'ใกล้เวลานัดหมาย'
                                  "
                                >
                                  <span class="font-14 font-w-400 mr-2">
                                    ใกล้เวลานัดหมาย
                                  </span>
                                  <v-icon color="#FFE0E0" small>
                                    mdi-checkbox-blank-circle
                                  </v-icon>
                                </div>

                                <div
                                  v-else-if="
                                    checkStatusDate(item) === 'หมดเวลานัดหมาย'
                                  "
                                >
                                  <span class="font-14 font-w-400 mr-2">
                                    หมดเวลานัดหมาย
                                  </span>
                                  <v-icon color="red" small>
                                    mdi-checkbox-blank-circle
                                  </v-icon>
                                </div>

                                <div v-else>
                                  <span class="font-14 font-w-400 mr-2">
                                    รอการนัดหมาย
                                  </span>
                                  <v-icon color="#4090ff" small>
                                    mdi-checkbox-blank-circle
                                  </v-icon>
                                </div>
                              </div>
                              <div v-else>
                                <span class="font-14 font-w-400 mr-2">
                                  นัดหมายใหม่
                                </span>
                                <span
                                  style="
                                    background-color: #f6c517;
                                    border-radius: 5px;
                                    color: #fff;
                                  "
                                  class="font-12 font-w-500 pl-1 pr-2"
                                >
                                  ใหม่!
                                </span>
                              </div>
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </div>
                </div>
                <div v-else class="mt-6 text-center">ไม่พบข้อมูล</div>
              </div>

              <!-- Tab 4 -->
              <div class="tab_4" v-if="tab === 3">
                <v-layout align-center>
                  <v-flex shrink class="mr-12 font-24 font-w-600">
                    {{ monthYear }}
                  </v-flex>
                  <v-flex shrink>
                    <v-layout>
                      <v-flex shrink>
                        <v-btn
                          style="border-radius: 10px 0px 0px 10px"
                          min-height="34"
                          min-width="34"
                          height="34"
                          width="34"
                          depressed
                          tile
                          :color="checkBack"
                          dark
                          @click="back(4)"
                        >
                          <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                      </v-flex>
                      <v-flex shrink>
                        <v-btn
                          dark
                          style="border-radius: 0px 10px 10px 0px"
                          depressed
                          tile
                          color="#4090FF"
                          min-height="34"
                          min-width="34"
                          height="34"
                          width="34"
                          @click="next(4)"
                        >
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                      </v-flex>
                      <v-flex shrink class="ml-4">
                        <v-btn
                          dark
                          style="border-radius: 29px"
                          depressed
                          tile
                          color="#6DCDEC"
                          @click="onClickToday()"
                        >
                          <span class="text-capitalize">Today</span>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>

                <!-- Appointment List -->
                <div
                  class="mt-6 appointment_list"
                  v-if="appointment_list.length > 0"
                >
                  <div
                    class="py-3 px-3 px-sm-6 card_appointment_list"
                    :class="appointment_list.length !== i + 1 ? 'mb-3 ' : ''"
                    v-for="(item, i) in appointment_list"
                    :key="i"
                    v-show="checkDate(item)"
                  >
                    <v-layout wrap align-center justify-space-between class="">
                      <v-flex xs6 sm3>
                        <v-layout align-center>
                          <v-flex shrink class="mr-4 text_appointment_list">
                            วันที่
                          </v-flex>
                          <v-flex shrink class="font-14">{{
                            item.date
                          }}</v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex xs6 sm3>
                        <v-layout align-center>
                          <v-flex shrink class="mr-4 text_appointment_list">
                            ช่วงเวลา
                          </v-flex>
                          <v-flex shrink class="font-14">
                            {{ item.start_time }}-{{ item.end_time }}
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex
                        xs12
                        sm6
                        class="d-flex justify-sm-end justify-center mt-3 mb-2 mt-sm-0 mb-sm-0"
                      >
                        <v-layout justify-sm-end justify-center align-center>
                          <v-flex shrink>
                            <span
                              class="font-16 font-w-500"
                              style="color: #4090ff"
                              v-if="item.status === 'ระบุเป็นวันว่างแล้ว'"
                            >
                              {{ item.status }}
                            </span>
                            <span
                              class="font-16 font-w-500 px-3 py-1"
                              style="
                                color: #6e49ec;
                                background: #e6e5fc;
                                border-radius: 30px;
                              "
                              v-if="item.status === 'นัดหมายแล้ว'"
                            >
                              {{ item.status }}
                            </span>
                          </v-flex>
                          <v-flex
                            shrink
                            v-if="item.status === 'ระบุเป็นวันว่างแล้ว'"
                            class="ml-3"
                          >
                            <v-btn
                              @click="cancel(item.id)"
                              color="#4090ff"
                              outlined
                              small
                              class="px-2"
                              style="border-radius: 20px"
                            >
                              <v-icon small class="mr-1"
                                >mdi-close-circle-outline</v-icon
                              >
                              <span
                                style="color: #5e616f"
                                class="font-16 font-w-500"
                              >
                                ยกเลิก
                              </span>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </div>
                </div>

                <div v-else class="mt-6 text-center">ไม่พบข้อมูล</div>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>

      <!-- Full Calendar -->
      <div class="mt-8 pa-5 full_calendar">
        <FullCalendar
          v-if="tab === 3"
          ref="fullCalendar"
          :options="calendarOptions"
        >
          <template v-slot:eventContent="arg">
            <div
              v-if="arg.event.extendedProps.type === 'ปรึกษาปัญหาส่วนตัว'"
              class="font-12 font-w-400"
              style="color: #5e616f"
            >
              <v-layout wrap>
                <v-flex shrink>
                  <v-icon color="#4090FF" small>
                    mdi-checkbox-blank-circle
                  </v-icon>
                  <span class="mx-1 font-w-500">ส่วนตัว:</span>
                </v-flex>
                <v-flex shrink>
                  <span>({{ arg.event.extendedProps.time }})</span>
                </v-flex>
              </v-layout>
            </div>

            <div
              v-else-if="arg.event.extendedProps.type === 'ปรึกษาเคส'"
              class="font-12 font-w-400"
              style="color: #5e616f"
            >
              <v-layout wrap>
                <v-flex shrink>
                  <v-icon color="#6E49EC" small>
                    mdi-checkbox-blank-circle
                  </v-icon>
                  <span class="mx-1 font-w-500">เคส:</span>
                </v-flex>
                <v-flex shrink>
                  <span>({{ arg.event.extendedProps.time }})</span>
                </v-flex>
              </v-layout>
            </div>
          </template>
        </FullCalendar>

        <FullCalendar
          v-if="tab !== 3"
          ref="fullCalendarAll"
          :options="calendarOptionAll"
        >
          <template v-slot:eventContent="arg">
            <div
              v-if="arg.event.extendedProps.type === 'ปรึกษาปัญหาส่วนตัว'"
              class="font-12 font-w-400"
              style="color: #5e616f"
            >
              <v-layout wrap>
                <v-flex shrink>
                  <v-icon color="#4090FF" small>
                    mdi-checkbox-blank-circle
                  </v-icon>
                  <span class="mx-1 font-w-500">ส่วนตัว:</span>
                </v-flex>
                <v-flex shrink>
                  <span>({{ arg.event.extendedProps.time }})</span>
                </v-flex>
              </v-layout>
            </div>

            <div
              v-else-if="arg.event.extendedProps.type === 'ปรึกษาเคส'"
              class="font-12 font-w-400"
              style="color: #5e616f"
            >
              <v-layout wrap>
                <v-flex shrink>
                  <v-icon color="#6E49EC" small>
                    mdi-checkbox-blank-circle
                  </v-icon>
                  <span class="mx-1 font-w-500">เคส:</span>
                </v-flex>
                <v-flex shrink>
                  <span>({{ arg.event.extendedProps.time }})</span>
                </v-flex>
              </v-layout>
            </div>
          </template>
        </FullCalendar>
      </div>
    </v-container>

    <!-- dialog ตั้งค่าวัน-เวลาว่าง -->
    <div class="text-center form_setting_datetime">
      <v-dialog
        v-model="dialog"
        :width="
          $vuetify.breakpoint.lgAndUp
            ? '40%'
            : $vuetify.breakpoint.mdAndUp
            ? '60%'
            : $vuetify.breakpoint.smAndUp
            ? '70%'
            : '90%'
        "
      >
        <v-card class="pt-6 pb-12 px-12" elevation="6">
          <div
            :class="$vuetify.breakpoint.xs ? 'font-26' : 'font-36'"
            class="font-w-500 text-center"
          >
            ตั้งค่าวัน-เวลาว่าง
          </div>
          <v-divider />
          <template>
            <v-form ref="form" v-model="valid" lazy-validation>
              <div class="my-4">
                <div class="mb-2">วันที่</div>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formSetting.date"
                      placeholder="เลือกวันที่"
                      readonly
                      :rules="[(v) => !!v || 'Date is required']"
                      outlined
                      dense
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :min="minDate"
                    v-model="formSetting.date"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </div>

              <div>
                <div class="mb-2">ช่วงเวลา</div>
                <v-select
                  outlined
                  dense
                  flat
                  hide-details
                  :rules="[(v) => !!v || 'Date is required']"
                  :items="timeList"
                  placeholder="เลือกช่วงเวลา"
                  solo
                  v-model="formSetting.time"
                ></v-select>
              </div>
              <v-row class="mt-4">
                <v-col>
                  <v-btn
                    :disabled="!valid"
                    class="mr-4"
                    :class="valid ? 'btn_blue_gradient' : 'btn_disable'"
                    @click="validate()"
                    :dark="valid"
                    block
                    height="58"
                  >
                    <span class="font-24 font-w-500 white--text">
                      ยืนยันการตั้งค่า
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </template>
        </v-card>
      </v-dialog>
    </div>

    <Loading :loading="isLoading"></Loading>
  </div>
</template>

<script>
import Topic from "../../../components/Topic.vue";
import moment from "moment";
import SERVICE from "../../../services/index";
import Loading from "../../../components/Loading.vue";

import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
export default {
  components: {
    Topic,
    Loading,
    FullCalendar,
  },
  data() {
    return {
      filter: "",
      mentors: [],
      calendarOptionAll: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: "dayGridMonth",
        events: [],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "",
        },
        dayMaxEvents: true,
      },

      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: "dayGridMonth",
        events: [],
        headerToolbar: {
          left: "",
          center: "",
          right: "",
        },
        dayMaxEvents: true,
      },

      minDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      ).toISOString(),
      appointment_list: [],
      cases: [],
      appointmentCases: [],
      personalAppointmentCases: [],
      valid: true,
      isLoading: false,
      dialog: false,
      menu: false,
      filterDate: moment().format("YYYY-MM-DD"),
      timeList: [
        "08:00-09:00",
        "09:00-10:00",
        "10:00-11:00",
        "11:00-12:00",
        "12:00-13:00",
        "13:00-14:00",
        "14:00-15:00",
        "15:00-16:00",
        "16:00-17:00",
        "17:00-18:00",
        "18:00-19:00",
        "19:00-20:00",
        "20:00-21:00",
        "21:00-22:00",
      ],
      formSetting: {
        date: "",
        time: "",
        mentor_id: "",
      },
      topic: {
        tab: 1,
        title_en: "For Provider",
        title_th: "ทั้งหมด",
      },
      tab: 0,
      tabs_staff: [
        { tab: 1, title_th: "ทั้งหมด", title_en: "For Provider" },
        { tab: 2, title_th: "ปรึกษาเคส", title_en: "Case" },
        {
          tab: 3,
          title_th: "ปรึกษาปัญหาส่วนตัว",
          title_en: "Personal Consultation Appointment",
        },
        {
          tab: 5,
          title_th: "หลักสูตรอบรม",
          title_en: "Course",
        },
      ],
      tabs_consultant: [
        { tab: 1, title_th: "ทั้งหมด", title_en: "For Provider" },
        { tab: 2, title_th: "ปรึกษาเคส", title_en: "Case" },
        {
          tab: 3,
          title_th: "ปรึกษาปัญหาส่วนตัว",
          title_en: "Personal Consultation Appointment",
        },
        {
          tab: 4,
          title_th: "รายการวัน-เวลาว่าง",
          title_en: "Days-Times Available List",
        },
      ],
    };
  },
  created() {
    this.getCaseAppointment();
    SERVICE.cases.getMentorList().then((res) => {
      this.mentors = res.data;
    });
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    monthYear() {
      return moment(this.filterDate).format("MMM YYYY");
    },
    checkBack() {
      if (moment().format("YYYY-MM-DD") === this.filterDate) {
        return "#D3D9E3";
      } else {
        return "#4090FF";
      }
    },
  },

  methods: {
    clearFilter() {
      this.filter = "";
    },
    async getMentors() {
      if (this.tab === 0) {
        const res = await this.getCaseAppointment();
        if (res === true) {
          let calendarApi = this.$refs.fullCalendarAll.getApi();
          calendarApi.today();
        }
      }
      if (this.tab === 1) {
        const res = await this.getAppointmentCases();
        if (res === true) {
          let calendarApi = this.$refs.fullCalendarAll.getApi();
          calendarApi.today();
        }
      }
      if (this.tab === 2) {
        const res = await this.getPersonalAppointmentCases();
        if (res === true) {
          let calendarApi = this.$refs.fullCalendarAll.getApi();
          calendarApi.today();
        }
      }
      if (this.tab === 3) {
        const res = await this.appointmentList(moment().format("YYYY-MM-DD"));
        if (res === true) {
          let calendarApi = this.$refs.fullCalendar.getApi();
          calendarApi.today();
        }
      }
    },
    async appointmentList(m) {
      this.isLoading = true;
      this.appointment_list = [];
      const res = await SERVICE.cases
        .appointmentList({ m: m, filter: this.filter })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          return false;
        });

      this.appointment_list = res.data;

      // set data ของ calendar
      this.calendarOptions.events = [];
      res.data.forEach((e) => {
        this.calendarOptions.events.push({
          start: e.date,
          end: e.date,
          overlap: false,
          display: "background",
          color: "#fff",
        });

        this.calendarOptions.events.push({
          date: e.date,
          time: e.start_time + "-" + e.end_time,
          type: e.type,
        });
      });

      this.isLoading = false;
      return true;
    },

    cancel(id) {
      this.$swal({
        title: "คุณแน่ใจไหม?",
        text: "คุณต้องการยกเลิก รายการวัน-เวลาว่าง ใช่ไหม",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4090FF",
        cancelButtonColor: "#BCC4CB",
        confirmButtonText: "แน่ใจ",
        cancelButtonText: "ไม่",
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          SERVICE.cases
            .cancelAppointmentList({ id: id })
            .then((res) => {
              this.isLoading = false;
              this.appointmentList(moment().format("YYYY-MM-DD"));
              // this.appointment_list = this.appointment_list.filter(
              //   (item) => item.id !== id
              // );

              this.$swal({
                title: "ยกเลิกสำเร็จ",
                icon: "success",
              });
            })
            .catch((err) => {
              console.log(err);
              this.isLoading = false;
            });
        }
      });
    },

    validate() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        this.formSetting.mentor_id = this.user.id;
        SERVICE.cases
          .saveAppointmentList(this.formSetting)
          .then((res) => {
            if (res === "success") {
              this.$swal({
                title: "ตั้งค่าวัน-เวลาว่าง สำเร็จ",
                icon: "success",
              });
              this.isLoading = false;
              this.dialog = false;
              this.formSetting = {
                date: "",
                time: "",
                mentor_id: "",
              };
              this.$refs.form.resetValidation();
              this.appointmentList(this.filterDate);
            } else if (res.response.status === 400) {
              this.$swal({
                title: "บันทึกไม่สำเร็จ!",
                text: res.response.data,
                icon: "error",
              });
              this.isLoading = false;
            } else {
              this.$swal({
                title: "บันทึกไม่สำเร็จ!",
                text: "มีบางอย่างผิดพลาด",
                icon: "error",
              });
              this.isLoading = false;
            }
          })
          .catch((err) => {
            console.log(err.response);
            this.$swal({
              title: "บันทึกไม่สำเร็จ!",
              text: err.response.data,
              icon: "error",
            });
            this.isLoading = false;
          });
      }
    },

    onClickToday() {
      this.appointmentList(moment().format("YYYY-MM-DD"));
      this.filterDate = moment().format("YYYY-MM-DD");

      let calendarApi = this.$refs.fullCalendar.getApi();
      calendarApi.today();
    },
    next() {
      this.calendarOptions.events = [];
      this.calendarOptionAll.events = [];
      var futureMonth = moment(this.filterDate).add(1, "M");
      this.filterDate = futureMonth.format("YYYY-MM-DD");
      this.appointmentList(this.filterDate);

      let calendarApi = this.$refs.fullCalendar.getApi();
      calendarApi.next();
    },

    back() {
      this.calendarOptions.events = [];
      this.calendarOptionAll.events = [];
      if (this.filterDate > moment().format("YYYY-MM-DD")) {
        var futureMonth = moment(this.filterDate).subtract(1, "M");
        this.filterDate = futureMonth.format("YYYY-MM-DD");
        this.appointmentList(this.filterDate);

        let calendarApi = this.$refs.fullCalendar.getApi();
        calendarApi.prev();
      }
    },

    async onClickTab(item) {
      this.topic = item;
      if (item.tab === 1) {
        const res = await this.getCaseAppointment();
        if (res === true) {
          let calendarApi = this.$refs.fullCalendarAll.getApi();
          calendarApi.today();
        }
      }
      if (item.tab === 2) {
        const res = await this.getAppointmentCases();
        if (res === true) {
          let calendarApi = this.$refs.fullCalendarAll.getApi();
          calendarApi.today();
        }
      }
      if (item.tab === 3) {
        const res = await this.getPersonalAppointmentCases();
        if (res === true) {
          let calendarApi = this.$refs.fullCalendarAll.getApi();
          calendarApi.today();
        }
      }
      if (item.tab === 4) {
        // const mentor = await this.getMentorAppointmentCases(1);
        const res = await this.appointmentList(moment().format("YYYY-MM-DD"));
        if (res === true) {
          let calendarApi = this.$refs.fullCalendar.getApi();
          calendarApi.today();
        }
      }
    },

    checkTime(item) {
      if (item.type === "ปรึกษาปัญหาส่วนตัว") {
        return item.start_time + "-" + item.end_time;
      } else {
        return item.appointment_chat
          ? item.appointment_chat.start_time +
              "-" +
              item.appointment_chat.end_time
          : "";
      }
    },

    checkNameStaff(item) {
      if (item && item.user) {
        return item.user.full_name;
      }
    },

    checkNamePosition(item) {
      if (item && item.user) {
        var profession = "";
        var institution = "";

        if (item.user.profession_other) {
          profession = item.user.profession_other;
        } else {
          if (item.user.profession) {
            profession = item.user.profession.short_title
              ? item.user.profession.short_title
              : item.user.profession.title;
          }
        }

        if (item.user.institution_other) {
          institution = item.user.institution_other;
        } else {
          if (item.user.institution) {
            institution = item.user.institution.short_title
              ? item.user.institution.short_title
              : item.user.institution.title;
          }
        }

        return profession
          ? "(" + profession + ")"
          : "" + institution
          ? "(" + institution + ")"
          : "";
      }
    },

    checkStatusDate(item) {
      const dateNow = moment(new Date()).format("YYYY-MM-DD");
      const timeNow = moment(new Date()).format("H:mm");

      // ถ้าถึงวันนัดหมาย
      if (dateNow === item.date) {
        // ถ้าถึงเวลานัดหมาย
        if (timeNow >= item.start_time && timeNow <= item.end_time) {
          return "ถึงเวลานัดหมาย";
        }
        // เลยระยะเวลาสิ้นสุดนัดหมาย
        else if (timeNow >= item.start_time && timeNow > item.end_time) {
          return "หมดเวลานัดหมาย";
        }
        // ถ้ายังไม่ถึงเวลานัดหมาย
        else {
          return "ใกล้เวลานัดหมาย";
        }
      }
      // ยังไม่ถึงวันนัดหมาย
      if (dateNow < item.date) {
        return "รอการนัดหมาย";
      }
    },

    getCaseAppointment() {
      this.cases = [];
      this.isLoading = true;
      SERVICE.cases
        .getCaseAppointment(this.filter)
        .then((res) => {
          this.cases = res;

          // set data ของ calendar
          this.calendarOptionAll.events = [];
          res.forEach((e) => {
            this.calendarOptionAll.events.push({
              start: e.date,
              end: e.date,
              overlap: false,
              display: "background",
              color: "#fff",
            });
            if (e.type === "ปรึกษาปัญหาส่วนตัว") {
              this.calendarOptionAll.events.push({
                date: e.date,
                time: e.start_time + "-" + e.end_time,
                type: e.type,
              });
            } else {
              this.calendarOptionAll.events.push({
                date: e.appointment_chat.date,
                time:
                  e.appointment_chat.start_time +
                  "-" +
                  e.appointment_chat.end_time,
                type: e.appointment_chat.type,
              });
            }
          });
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },

    async getAppointmentCases() {
      this.isLoading = true;
      this.appointmentCases = [];
      const res = await SERVICE.cases
        .getAppointmentCaseMentor(this.filter)
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          return false;
        });

      this.appointmentCases = res;

      // set data ของ calendar
      this.calendarOptionAll.events = [];
      res.forEach((e) => {
        this.calendarOptionAll.events.push({
          start: e.date,
          end: e.date,
          overlap: false,
          display: "background",
          color: "#fff",
        });
        this.calendarOptionAll.events.push({
          date: e.appointment_chat.date,
          time:
            e.appointment_chat.start_time + "-" + e.appointment_chat.end_time,
          type: e.appointment_chat.type,
        });
      });

      this.isLoading = false;
      return true;
    },

    async getPersonalAppointmentCases() {
      this.isLoading = true;
      this.personalAppointmentCases = [];
      const res = await SERVICE.cases
        .getPersonalAppointmentCaseMentor(this.filter)
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          return false;
        });

      this.personalAppointmentCases = res;

      // set data ของ calendar
      this.calendarOptionAll.events = [];
      res.forEach((e) => {
        this.calendarOptionAll.events.push({
          start: e.date,
          end: e.date,
          overlap: false,
          display: "background",
          color: "#fff",
        });
        this.calendarOptionAll.events.push({
          date: e.date,
          time: e.start_time + "-" + e.end_time,
          type: e.type,
        });
      });

      this.isLoading = false;
      return true;
    },

    copy(link) {
      navigator.clipboard.writeText(link);
      this.$swal({
        title: "Copied",
        icon: "success",
        timer: 1000,
        showConfirmButton: false,
      });
    },

    handleDateClick: function (arg) {
      alert("date click! " + arg.dateStr);
    },

    checkDate(item) {
      var date = new Date(item.date + " " + item.start_time);
      var now = new Date();
      if (date >= now) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
// ************* Start Custom Full calendar
:root {
  --fc-button-hover-bg-color: #4090ff !important;
  --fc-button-active-bg-color: #4090ff !important;
  --fc-button-hover-border-color: #4090ff !important;
  --fc-button-active-border-color: #4090ff !important;
}
.fc .fc-daygrid-body-balanced .fc-daygrid-day-events {
  position: unset !important;
}
.fc .fc-daygrid-day-bottom {
  font-size: 12px;
  padding: 2px 3px 0;
}

.fc .fc-bg-event {
  background: #fff;
  opacity: 1;
}

.fc-theme-standard td {
  border: 1px solid #bcc4cb;
  background: #edf0f6;
}
.fc-theme-standard th {
  border: 1px solid #bcc4cb;
  background: #fff;
}
.fc-h-event {
  border: unset !important;
  background: transparent !important;
  margin-bottom: 2px;
}
th.fc-col-header-cell a {
  color: #bcc4cb !important;
}
.fc .fc-daygrid-day-number {
  position: relative;
  z-index: 4;
  padding: 4px;
  color: #5e616f;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: #d8effe !important;
}
.fc .fc-button-primary {
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #4090ff;
  border-color: #4090ff;
}

.fc .fc-button-primary:disabled {
  color: #fff;
  background-color: #6dcdec;
  border-color: #6dcdec;
  border-radius: 30px;
  width: 90px;
}

.fc .fc-day-other .fc-daygrid-day-top {
  opacity: 1;
}

button.fc-today-button.fc-button.fc-button-primary {
  color: #fff;
  background-color: #6dcdec;
  border-color: #6dcdec;
  border-radius: 30px;
  width: 90px;
}
button.fc-prev-button.fc-button.fc-button-primary {
  display: flex;
}
.full_calendar {
  background: #fff;
  border-radius: 5px;
}

// ************* End Custom Full calendar

.appointment_list {
  position: relative;
  max-height: 350px;
  overflow: scroll;
  background: transparent;
  border-bottom: 1px solid #dcdcdc;
  border-radius: 6px;
  padding: 1px;

  .shadow-list {
    position: sticky;
    height: 109px;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
  }
}
.card_appointment_list {
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.text_appointment_list {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #bcc4cb;
}

.form_setting_datetime {
  .v-dialog {
    border-radius: 20px;
  }
}
.mental {
  background: linear-gradient(180deg, #ffffff 0%, #edf0f6 100%);
  min-height: calc(100vh - 236px);
  color: #5e616f;

  .tab_list {
    max-height: 350px;
    overflow: auto;
    padding: 1px;
    position: relative;
    border-bottom: 1px solid #e3e3e3af;
    border-top: 1px solid #e3e3e3af;
    border-radius: 6px;
  }
  .tab_1 {
    max-height: 350px;
    overflow: auto;
    padding: 1px;
    position: relative;
    border-bottom: 1px solid #e3e3e3af;
    border-top: 1px solid #e3e3e3af;
    border-radius: 6px;

    .overlay_card {
      height: 109px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 100%
      );
      position: sticky;
      bottom: 0;
    }
  }
  .card_item {
    background: #ffffff;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }

  .txt_gray {
    color: #bcc4cb;
  }
  .txt_purple {
    color: #6e49ec;
  }
  .txt_blue {
    color: #4090ff;
  }
  .txt_green {
    color: #80c843;
  }
}
.tab_item {
  .theme--light.v-tabs-items {
    background-color: transparent;
  }
  .v-window {
    overflow: unset !important;
  }
}
</style>
