<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" style="padding: 2px">
        <CourseMain :model="course.new_course"></CourseMain>
      </v-col>
      <v-col cols="12" md="6" style="padding: 6px">
        <div
          class="con__course"
          v-for="(item, i) in course.near_start_course"
          :key="i"
        >
          <CoursePanel
            :topic="item.name"
            :image="item.image_training_courses"
            :content="item.course_details"
            :model="item"
          ></CoursePanel>
        </div>
      </v-col>
    </v-row>
    <div class="my-10 d-flex justify-center">
      <v-btn
        @click="goto('course')"
        min-width="228px"
        height="44px"
        class="btn-blue"
        rounded
      >
        ดูหลักสูตรอบรมทั้งหมด >
      </v-btn>
    </div>
  </div>
</template>

<script>
import CoursePanel from "./CoursePanel.vue";
import CourseMain from "./CourseMain.vue";
import { mapState } from "vuex";

export default {
  components: {
    CoursePanel,
    CourseMain,
  },
  computed: {
    ...mapState({
      course: (state) => state.Course.courseHome,
    }),
  },
  created() {
    this.$store.dispatch("Course/onCourseHome");
  },
  methods: {
    goto(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style>
.con__course {
  height: 50%;
  padding: 2px 0px;
  margin-bottom: 5px;
}
.item__card__course {
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 25%);
  border-radius: 5px;
}
</style>
