<template>
  <v-container
    style="padding: 0px"
    class="mw-1150 d-flex justify-space-between"
  >
    <Loading :loading="isLoading"></Loading>
    <v-container>
      <div class="mt-2">
        <BreadCrumbs :items="items"></BreadCrumbs>
      </div>
      <div class="mb-7 mt-1">
        <Topic eng="Knowledge Media" thai="สื่อความรู้"></Topic>
      </div>
      <!-- <MediaShortCut
        @loadingDone="isLoading = false"
        :isPaginate="true"
      ></MediaShortCut> -->

      <v-row>
        <v-col
          class="my-2 px-2 py-0"
          cols="12"
          md="4"
          sm="6"
          v-for="(item, i) in categorys"
          :key="i"
        >
          <div class="media__menu">
            <v-hover v-slot="{ hover }">
              <v-card
                @click="onClickCate(i)"
                class="px-2 py-2 pointer"
                :elevation="hover ? 1 : 1"
              >
                <v-layout class="d-flex align-center">
                  <v-flex>
                    <v-img
                      width="40px"
                      height="40px"
                      :src="require(`@/assets/mock/media_panel_${i}.png`)"
                    ></v-img>
                  </v-flex>
                  <v-flex>
                    <div
                      :class="
                        $vuetify.breakpoint.smAndDown
                          ? 'font-24 text-black'
                          : 'font-18 text-black'
                      "
                    >
                      {{ item }}
                    </div>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-hover>
          </div>
        </v-col>
      </v-row>

      <div class="my-10" v-if="articles.length > 0">
        <v-row wrap>
          <v-col
            cols="12"
            sm="3"
            class="mb-8"
            style="padding: 6px"
            v-for="(item, i) in articles"
            :key="i"
          >
            <MediaPanel
              :image="item.image"
              :date="item.release_date"
              :topic="item.topic"
              :id="item.id"
            ></MediaPanel>
          </v-col>
        </v-row>
      </div>
      <div v-else class="my-10">
        <h1 class="text-center">ไม่พบสื่อความรู้</h1>
      </div>

      <v-pagination
        class="my-10"
        v-model="paginate.page"
        :length="paginate.last_page"
        circle
        @input="getData"
      ></v-pagination>
    </v-container>
  </v-container>
</template>

<script>
import Topic from "../components/Topic.vue";
import Loading from "../components/Loading.vue";
import MediaShortCut from "../components/Media/MediaShortCut.vue";
import BreadCrumbs from "../components/BreadCrumbs.vue";
import MediaPanel from "../components/Media/MediaPanel.vue";
export default {
  components: {
    // MediaShortCut,
    BreadCrumbs,
    Loading,
    Topic,
    MediaPanel,
  },

  data() {
    return {
      category_id: "",
      categorys: [],
      isRender: true,
      isLoading: false,
      paginate: {
        page: 1,
        current_page: 0,
        last_page: 0,
        total: 0,
      },
      articles: [],
      items: [
        {
          text: "หน้าแรก",
          disabled: false,
          href: "/",
        },
        {
          text: "สื่อความรู้",
          disabled: true,
        },
      ],
    };
  },

  async created() {
    this.isLoading = true;
    await Promise.all([this.getBanner(), this.getData(1)]);
    this.isLoading = false;
  },

  methods: {
    async getData(page) {
      this.isLoading = true;
      this.isRender = false;
      this.articles = [];
      const res = await this.$store.dispatch("Media/get", {
        category_id: this.category_id,
        paginate: 8,
        page: page,
      });
      this.categorys = res.group_name;
      this.articles = res.data;
      this.paginate = {
        page: page,
        current_page: res.current_page,
        last_page: res.last_page,
        total: res.total,
      };
      this.isLoading = false;
    },
    onClickCate(id) {
      this.category_id = id;
      this.getData(1);
    },
    async getBanner() {
      await this.$store.dispatch("Banner/get").then((res) => {
        this.banner = res;
      });
    },
  },
};
</script>

<style></style>
