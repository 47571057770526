<template>
  <v-btn
    v-if="isShow"
    absolute
    :style="`z-index: 2; background-color: ${mainColor}`"
    :width="width"
    :height="height"
    dark
  >
    <span class="font-weight-bold text-capitalize font-14">{{ badgeText }}</span>
  </v-btn>
</template>

<script>
import moment from "moment";
export default {
  props: ["width", "height", "text", "color", "releaseDate","isFix"],
  data() {
    return {
      mainColor: "",
      badgeText: "",
      isShow:true
    };
  },
  created() {
    this.mainColor = this.color;
    this.badgeText = this.text;
    if(!this.isFix){
    const timeNow = moment();
    const endTime = moment(this.releaseDate, "YYYY-MM-DD");
      if (endTime.isAfter(timeNow)) {
        this.mainColor = "#009982";
        this.badgeText = "Upcoming!";
        this.isShow = true
      } else if(timeNow.diff(endTime, 'days') <= 3) {
        this.mainColor = "#80C843";
        this.badgeText = "NEW!";
        this.isShow = true
      }else{
        this.isShow = false
      }
    }
  },
};
</script>

<style>
</style>