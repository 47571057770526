import Service from "../../services/index";

const state = {
  paginate: {
    page: 0,
    itemsPerPage: 0,
    pageStart: 0,
    pageStop: 0,
    pageCount: 0,
  },
  courses: [],
  courseHome: {
    new_course: null,
    near_start_course: null,
  },
  course: null,
};

const getters = {};
const actions = {
  // eslint-disable-next-line no-unused-vars
  get({ commit }, data) {
    return Service.course.get(data).then((response) => {
      commit("SET_COURSES", response.data);
      commit("SET_PAGINATE", {
        page: response.current_page,
        itemsPerPage: response.per_page,
        pageStart: response.from,
        pageStop: response.last_page,
        pageCount: Math.ceil(response.total / response.per_page),
      });
      return response.data;
    });
  },
  courseHistory({ commit }, data) {
    return Service.course.courseHistory(data).then((response) => {
      return response;
    });
  },
  show({ commit }, model) {
    return Service.course.show(model).then((response) => {
      commit("SET_COURSE", response);
      return response;
    });
  },
  onRegister({ commit }, payload) {
    return Service.course.register(payload).then((response) => {
      if (response !== 401) {
        commit("SET_COURSE", response);
      }
      return response;
    });
  },
  onCourseHome({ commit }) {
    return Service.course.courseHome().then((response) => {
      commit("SET_COURSE_HOME", response);
      return response;
    });
  },
  // eslint-disable-next-line no-unused-vars
  onCertificate({ commit }, payload) {
    return Service.course.certificate(payload).then((response) => {
      return response;
    });
  },
};
const mutations = {
  SET_COURSES: (state, data) => {
    state.courses = data;
  },
  SET_COURSE: (state, data) => {
    state.course = data;
  },
  SET_COURSE_HOME: (state, data) => {
    state.courseHome = data;
  },
  SET_PAGINATE: (state, data) => {
    state.paginate = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
