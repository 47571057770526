//! Local
// export const domain = "http://127.0.0.1:8000/api";
// export const domain_frontend = "http://127.0.0.1:8080";
// export const storage_path = "http://127.0.0.1:8000/storage";
// export const banner_path = "http://127.0.0.1:8080/";

//! Testing
export const domain = "https://oscc-chat-api.ksta.co/api";
export const domain_frontend = "https://oscc-chat.ksta.co";
export const storage_path = "https://oscc-chat-api.ksta.co/storage";
export const banner_path = "https://oscc-chat-api.ksta.co/";

//! Prod
// export const domain = "https://oscc.consulting:8443/api";
// export const domain_frontend = "https://oscc.consulting";
// export const storage_path = "https://oscc.consulting:8443";
// export const banner_path = "https://oscc.consulting:8443/";

// Pusher API Key
// local
// export const pusher_key = "4b117f19763a5ea34036";
// export const pusher_cluster = "ap1";
// production:
export const pusher_key = "5cfc84ffa9929a859a62";
export const pusher_cluster = "ap1";

export const menu = [
  { name: "หน้าแรก", link: "Home", isActive: false, isShow: true },
  { name: "หลักสูตรอบรม", link: "CourseList", isActive: false, isShow: true },
  {
    name: "กิจกรรมข่าวสาร",
    link: "EventNewsList",
    isActive: false,
    isShow: true,
  },
  {
    name: "สื่อความรู้",
    link: "MediaList",
    subLink: "Media",
    isActive: false,
    isShow: true,
  },
  { name: "เกี่ยวกับเรา", link: "About", isActive: false, isShow: true },
  // { name: "ติดต่อเรา", link: "", isActive: false, isShow: true },
];
