import moment from "moment";
export function changeDate(date, start, end) {
  moment.locale("th");
  const year = moment(date, "YYYY-MM-DD").add(543, "years").format("YY");
  const startTime = moment(start, "HH:mm:ss").format("HH.mm");
  const endTime = moment(end, "HH:mm:ss").format("HH.mm");
  const thYear = parseInt(year);
  return `${moment(date, "YYYY-MM-DD").format(
    "dd DD MMM"
  )} ${thYear} / ${startTime} - ${endTime}น.`;
}
