<template>
  <div :class="`px-2 font-18 py-1 badge-${this.id}`">
    <v-layout align-center class="d-flex">
      <img
        width="32px"
        height="32px"
        :src="require(`@/assets/mock/media_panel_${this.id}.png`)"
      />
      <v-flex class="font-18">{{ category }}</v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: ["id", "category"],
  data() {
    return {
      selected: null,
    };
  },
};
</script>

<style>
.badge-1 {
  border-radius: 10px;
  color: #80c843;
  background: #cff0b3;
}
.badge-2 {
  border-radius: 10px;
  color: #4090ff;
  background: #d8effe;
}
.badge-3 {
  border-radius: 10px;
  color: #ff7070;
  background: #ffe0e0;
}
.badge-4 {
  border-radius: 10px;
  color: #6e49ec;
  background: #e6e5fc;
}
.badge-5 {
  border-radius: 10px;
  color: #ffd233;
  background: #fffae7;
}
.badge-6 {
  border-radius: 10px;
  color: #6dcdec;
  background: #ebfcfe;
}
</style>
