<template>
  <v-hover v-slot="{ hover }">
    <v-card
      @click="goto(model.id)"
      class="mb-2 pointer"
      height="100%"
      elevation="3 "
      style="color: #5e616f"
    >
      <div>
        <v-img
          :aspect-ratio="19 / 10"
          :class="hover ? 'img-hoverr' : ''"
          :src="storage_path + image"
          style="border-top-left-radius: 5px; border-top-right-radius: 5px"
        >
          <div class="card__hover" v-if="hover">
            <div
              class="d-flex justify-center align-center"
              style="height: 100%"
            >
              <v-img width="90" height="90" contain :src="readMore" />
            </div>
          </div>
        </v-img>
      </div>
      <v-layout
        wrap
        justify-center
        align-center
        class="px-3 pt-3"
        style="height: 120px"
      >
        <v-flex xs12>
          <span class="overflow-ellipsis-2 font-16">{{ topic }}</span>
        </v-flex>
        <v-flex xs12>
          <span class="ell-2 text-black font-12">
            <!-- <div class="v-html" v-html="content"></div> -->
          </span>
        </v-flex>
        <v-flex xs12 class="mt-2">
          <div class="d-flex justify-space-between font-13">
            <div>
              <i class="bi bi-calendar-minus" style="color: #bcc4cb"></i>
              {{ date | moment("DD/MM/YYYY") }}
            </div>
            <a class="under-line">อ่านต่อ</a>
          </div>
        </v-flex>
      </v-layout>
    </v-card>
  </v-hover>
</template>

<script>
import ReadMore from "../ReadMoreHover.vue";
import { storage_path } from "../../util/Constant";
import readMore from "../../assets/icon/readmore.png";
export default {
  props: ["topic", "content", "date", "image", "model"],
  components: {
    // ReadMore,
  },
  data() {
    return {
      readMore: readMore,
      storage_path: storage_path,
    };
  },
  methods: {
    goto(id) {
      this.$router.push(`/event-news/${id}`);
    },
  },
};
</script>

<style>
.card__hover {
  background: rgba(0, 0, 0, 0.6) !important;
  height: 100%;
  width: 100%;
}
</style>

<style scoped>
.v-html >>> img {
  width: 100% !important;
  height: auto !important;
}
.v-html >>> p {
  margin-bottom: 0px !important;
  font-size: 12px !important;
}
.v-html >>> span {
  margin-bottom: 0px !important;
  font-size: 12px !important;
}
.v-html >>> iframe {
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 1.78341013825;
}
@media only screen and (max-width: 768px) {
  .v-html >>> img {
    width: 100% !important;
    height: auto !important;
  }
  .v-html >>> iframe {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 1.78341013825;
  }
}
.card_label {
  position: absolute;
  top: 0;
  z-index: 1;
  background: aquamarine;
  border-radius: 5px;
  width: 65px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
