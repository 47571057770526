<template>
  <div class="mental__form">
    <v-container>
      <Topic
        eng="Create Mental Health care"
        thai="สร้างนัดหมายปรึกษาปัญหาส่วนตัว"
      />

      <v-layout justify-center>
        <v-flex xs12 sm11 md12 lg10>
          <v-form lazy-validation>
            <v-stepper
              alt-labels
              flat
              v-model="e1"
              elevation="0"
              class="mental__step mt-4"
              color="success"
            >
              <v-layout justify-center>
                <v-flex xs12 sm11 md10>
                  <v-stepper-header class="mb-8 px-0 px-sm-16">
                    <v-stepper-step :complete="e1 > 1" step="1">
                      <span
                        style="color: #5e616f; font-weight: 400"
                        class="font-14 font-w-400"
                      >
                        เลือกที่ปรึกษา
                      </span>
                    </v-stepper-step>

                    <v-divider
                      :style="
                        e1 === 2 || e1 === 3
                          ? { border: '2px solid #4090ff' }
                          : {}
                      "
                    ></v-divider>

                    <v-stepper-step
                      style="box-shadow: unset; color: #5e616f"
                      :complete="e1 > 2"
                      step="2"
                    >
                      <span style="color: #5e616f" class="font-14 font-w-400"
                        >วันที่และเวลา</span
                      >
                    </v-stepper-step>

                    <v-divider
                      :style="e1 === 3 ? { border: '2px solid #4090ff' } : {}"
                    ></v-divider>

                    <v-stepper-step step="3">
                      <span style="color: #5e616f" class="font-14 font-w-400"
                        >ยืนยัน</span
                      >
                    </v-stepper-step>
                  </v-stepper-header>
                </v-flex>
              </v-layout>

              <v-stepper-items>
                <!-- Slide 1 -->
                <v-stepper-content step="1">
                  <div class="mb-n3 font-18 font-w-500">เลือกที่ปรึกษา</div>

                  <v-radio-group
                    color="#4090FF"
                    v-model="form.consultant_type"
                    row
                  >
                    <v-row justify="space-between" no-gutters>
                      <v-col class="pa-1">
                        <v-layout class="mental_checkbox_card px-3 px-sm-6">
                          <v-flex>
                            <v-radio
                              color="#4090FF"
                              label="ระบุผู้ให้คำปรึกษา"
                              value="ระบุผู้ให้คำปรึกษา"
                            >
                              <template v-slot:label>
                                <div class="font-24 font-w-500">
                                  ระบุผู้ให้คำปรึกษา
                                </div>
                              </template>
                            </v-radio>
                          </v-flex>
                          <v-flex shrink>
                            <v-icon
                              large
                              :color="
                                form.consultant_type === 'ระบุผู้ให้คำปรึกษา'
                                  ? '#4090FF'
                                  : ''
                              "
                            >
                              mdi-checkbox-marked-circle-outline
                            </v-icon>
                          </v-flex>
                        </v-layout>
                      </v-col>
                      <v-col class="pa-1">
                        <v-layout class="mental_checkbox_card px-3 px-sm-6">
                          <v-flex>
                            <v-radio
                              color="#4090FF"
                              label="ไม่ระบุผู้ให้คำปรึกษา"
                              value="ไม่ระบุผู้ให้คำปรึกษา"
                              @click="clearConsultants()"
                            >
                              <template v-slot:label>
                                <div class="font-24 font-w-500">
                                  ไม่ระบุผู้ให้คำปรึกษา
                                </div>
                              </template>
                            </v-radio>
                          </v-flex>
                          <v-flex shrink>
                            <v-icon
                              large
                              :color="
                                form.consultant_type === 'ไม่ระบุผู้ให้คำปรึกษา'
                                  ? '#4090FF'
                                  : ''
                              "
                            >
                              mdi-account-check
                            </v-icon>
                          </v-flex>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-radio-group>

                  <v-radio-group color="#4090FF" v-model="form.mentor_id" row>
                    <v-row
                      no-gutters
                      class="mt-4"
                      v-if="form.consultant_type === 'ระบุผู้ให้คำปรึกษา'"
                    >
                      <v-col
                        class="pa-1"
                        cols="12"
                        sm="4"
                        md="3"
                        v-for="(item, i) in consultantList"
                        :key="i"
                      >
                        <div
                          class="consultant_card"
                          @click="onSelectConsultant(item)"
                          :class="
                            item.id === form.mentor_id
                              ? 'consultant_card_active'
                              : ''
                          "
                        >
                          <v-radio
                            readonly
                            style="position: absolute; top: 10px; right: -10px"
                            color="#4090FF"
                            :value="item.id"
                          >
                          </v-radio>

                          <div>
                            <v-img
                              class="consultant_profile_image"
                              :src="
                                item.image_profile
                                  ? storage_path + item.image_profile
                                  : require('../../../assets/images/default-image-profile.png')
                              "
                            ></v-img>
                          </div>

                          <div class="consultant_name mt-2">
                            {{ item.full_name }}
                          </div>

                          <div class="consultant_profession mt-1">
                            {{
                              item.profession_other
                                ? item.profession_other
                                : item.profession && item.profession.title
                                ? item.profession.title
                                : ""
                            }}
                          </div>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-card
                                v-on="on"
                                flat
                                class="consultant_institution mt-1 ell-2"
                              >
                                {{
                                  item.institution_other
                                    ? item.institution_other
                                    : item.institution && item.institution.title
                                    ? item.institution.title
                                    : ""
                                }}
                              </v-card>
                            </template>
                            <span>
                              {{
                                item.institution_other
                                  ? item.institution_other
                                  : item.institution && item.institution.title
                                  ? item.institution.title
                                  : ""
                              }}
                            </span>
                          </v-tooltip>
                        </div>
                      </v-col>
                    </v-row>
                  </v-radio-group>

                  <v-layout justify-space-between my-16>
                    <v-flex shrink>
                      <v-btn
                        height="58"
                        outlined
                        color="#BCC4CB"
                        style="border-radius: 30px"
                        width="165"
                        disabled
                      >
                        <v-icon>mdi-chevron-left</v-icon>
                        <span class="font-18 font-w-500">ย้อนกลับ</span>
                      </v-btn>
                    </v-flex>
                    <v-flex shrink>
                      <v-btn
                        height="58"
                        color="#4090FF"
                        style="border-radius: 30px"
                        width="165"
                        dark
                        depressed
                        @click="next(2)"
                      >
                        <span class="font-18 font-w-500">ถัดไป</span>
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-stepper-content>

                <!-- Slide 2 -->
                <v-stepper-content step="2">
                  <div v-if="e1 === 2">
                    <div class="font-18 font-w-500 mb-n3 px-6">เลือกวันที่</div>
                    <div>
                      <v-sheet elevation="0" color="transparent">
                        <v-slide-group
                          v-model="date"
                          class="pa-4"
                          center-active
                          show-arrows
                          mandatory
                          prev-icon="mdi-chevron-left-circle-outline"
                          next-icon="mdi-chevron-right-circle-outline"
                        >
                          <v-slide-item
                            v-for="(item, i) in form.consultant_type ===
                            'ระบุผู้ให้คำปรึกษา'
                              ? consultant.dates
                              : dateAll"
                            :key="i"
                            @click.native="setValueDate(item, i)"
                            v-slot="{ toggle }"
                          >
                            <div
                              :class="
                                form.date === i ? 'active_select_date' : ''
                              "
                              class="select_date ma-1"
                              height="70"
                              width="98"
                              @click="toggle"
                            >
                              <div class="font-14 font-w-300">
                                {{ convertDayName(i) }}
                              </div>
                              <div class="font-16 font-w-500">
                                {{ convertDate(i) }}
                              </div>
                            </div>
                          </v-slide-item>
                        </v-slide-group>
                      </v-sheet>
                    </div>

                    <div v-if="form.date">
                      <div class="mb-n3 font-18 mt-8 px-6 font-w-500">
                        เลือกช่วงเวลา
                      </div>
                      <v-sheet elevation="0" color="transparent">
                        <v-slide-group
                          v-model="time"
                          class="pa-4"
                          center-active
                          mandatory
                          show-arrows
                          prev-icon="mdi-chevron-left-circle-outline"
                          next-icon="mdi-chevron-right-circle-outline"
                        >
                          <v-slide-item
                            class="slide_item"
                            v-for="(item, i) in times"
                            :key="i"
                            @click.native="form.time = item"
                            v-slot="{ toggle }"
                          >
                            <div
                              :class="
                                form.time === item ? 'active_select_date' : ''
                              "
                              class="select_time ma-1"
                              @click="toggle"
                            >
                              <div class="font-14 font-w-300">
                                {{ item }}
                              </div>
                            </div>
                          </v-slide-item>
                        </v-slide-group>
                      </v-sheet>
                    </div>
                  </div>

                  <v-layout justify-space-between my-16>
                    <v-flex shrink>
                      <v-btn
                        height="58"
                        outlined
                        color="#BCC4CB"
                        style="border-radius: 30px"
                        width="165"
                        @click="next(1)"
                      >
                        <v-icon>mdi-chevron-left</v-icon>
                        <span class="font-18 font-w-500">ย้อนกลับ</span>
                      </v-btn>
                    </v-flex>
                    <v-flex shrink>
                      <v-btn
                        height="58"
                        color="#4090FF"
                        style="border-radius: 30px"
                        width="165"
                        dark
                        depressed
                        @click="next(3)"
                      >
                        <span class="font-18 font-w-500">ถัดไป</span>
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-stepper-content>

                <!-- Slide 3 -->
                <v-stepper-content step="3">
                  <div v-if="e1 === 3" style="color: #5e616f">
                    <v-divider class="mb-10" />

                    <div
                      class="font-20 font-w-500 mb-6"
                      style="text-decoration-line: underline; color: #4090ff"
                    >
                      เลือกที่ปรึกษา
                    </div>

                    <v-layout wrap>
                      <v-flex xs12 sm4 md6 class="mb-6">
                        <div class="font-18 font-w-500" style="color: #bcc4cb">
                          เลือกที่ปรึกษา
                        </div>
                        <div>{{ form.consultant_type }}</div>
                      </v-flex>
                      <v-flex xs12 sm8 md6>
                        <div class="font-18 font-w-500" style="color: #bcc4cb">
                          รายชื่อที่ปรึกษา
                        </div>
                        <v-layout v-if="consultant">
                          <v-flex shrink class="mr-4">
                            <v-img
                              style="border-radius: 100%"
                              v-if="consultant.image_profile"
                              width="67"
                              aspect-ratio="1"
                              :src="consultant.image_profile"
                            ></v-img>
                            <v-img
                              style="border-radius: 100%"
                              v-else
                              width="67"
                              aspect-ratio="1"
                              src="../../../assets/images/default-image-profile.png"
                            ></v-img>
                          </v-flex>
                          <v-flex>
                            <div
                              :class="
                                $vuetify.breakpoint.smAndDown
                                  ? 'font-w-500 font-18'
                                  : 'font-w-500 font-26'
                              "
                            >
                              {{ consultant.full_name }}
                            </div>
                            <div
                              :class="
                                $vuetify.breakpoint.smAndDown
                                  ? 'font-w-500 font-16'
                                  : 'font-w-500 font-18'
                              "
                            >
                              {{
                                consultant.profession_other
                                  ? consultant.profession_other
                                  : consultant.profession &&
                                    consultant.profession.title
                                  ? consultant.profession.title
                                  : ""
                              }}
                            </div>
                            <div class="font-w-300 font-16">
                              {{
                                consultant.institution_other
                                  ? consultant.institution_other
                                  : consultant.institution &&
                                    consultant.institution.title
                                  ? consultant.institution.title
                                  : ""
                              }}
                            </div>
                          </v-flex>
                        </v-layout>
                        <div v-else>-</div>
                      </v-flex>
                    </v-layout>

                    <v-divider class="my-10" />

                    <div
                      class="font-20 font-w-500 mb-6"
                      style="text-decoration-line: underline; color: #4090ff"
                    >
                      วันที่และเวลา
                    </div>

                    <v-layout wrap>
                      <v-flex xs12 sm4 md6 class="mb-6">
                        <div class="font-18 font-w-500" style="color: #bcc4cb">
                          วันที่
                        </div>
                        <div>{{ fullDateThai(form.date) }}</div>
                      </v-flex>
                      <v-flex xs12 sm8 md6>
                        <div class="font-18 font-w-500" style="color: #bcc4cb">
                          เวลา
                        </div>
                        <div>{{ form.time }}</div>
                      </v-flex>
                    </v-layout>

                    <v-divider class="my-12" />
                  </div>

                  <v-layout justify-space-between my-16>
                    <v-flex shrink>
                      <v-btn
                        height="58"
                        outlined
                        color="#BCC4CB"
                        style="border-radius: 30px"
                        width="165"
                        @click="next(2)"
                      >
                        <v-icon>mdi-chevron-left</v-icon>
                        <span class="font-18 font-w-500">ย้อนกลับ</span>
                      </v-btn>
                    </v-flex>
                    <v-flex shrink>
                      <v-btn
                        height="58"
                        color="#4090FF"
                        style="border-radius: 30px"
                        width="165"
                        dark
                        depressed
                        @click="submit()"
                      >
                        <span class="font-18 font-w-500">สร้างนัดหมาย</span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-form>
        </v-flex>
      </v-layout>

      <v-dialog v-model="dialog" width="600">
        <v-card class="py-6 px-4 px-sm-14">
          <div class="font-w-500 font-28 text-center mt-3">สำเร็จ!</div>
          <v-layout justify-center>
            <v-flex class="">
              <v-img
                aspect-ratio="1"
                src="../../../assets/icon/document-text.png"
              ></v-img>
            </v-flex>
            <v-flex xs11 class="pl-3">
              <div class="font-18 font-w-500 mb-4">
                ระบบจะส่งลิงค์ zoom ให้ทางอีเมลก่อนวันนัดหมาย 1 วัน
              </div>
              <div class="font-14 font-w-300">
                <div>“หากท่านต้องการยกเลิกนัดหมายนี้</div>
                <div>
                  กรุณาแจ้งแอดมินล่วงหน้าก่อนถึงวันนัดหมายอย่างน้อย 2 วัน
                </div>
                <v-layout class="d-flex align-center" wrap>
                  <v-flex shrink>ผ่านแชท</v-flex>
                  <v-flex shrink>
                    <v-img
                      contain
                      aspect-ratio="1"
                      width="40"
                      height="40"
                      src="../../../assets/icon/Messenger.png"
                    ></v-img>
                  </v-flex>
                  <v-flex shrink>
                    หรือ อีเมล OSCC.Consulting@gmail.com”
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
          </v-layout>

          <div class="d-flex justify-center my-6">
            <v-btn
              min-width="200"
              class="btn-blue-gradient"
              text
              @click="closeDialog"
            >
              ตกลง
            </v-btn>
          </div>
        </v-card>
      </v-dialog>

      <Loading :loading="isLoading" />
    </v-container>
  </div>
</template>

<script>
import Topic from "../../../components/Topic.vue";
import moment from "moment";
import SERVICE from "../../../services/index";
import Loading from "../../../components/Loading.vue";
import { storage_path } from "../../../util/Constant";
export default {
  components: { Topic, Loading },
  data() {
    return {
      storage_path: storage_path,
      dialog: false,
      e1: 1,
      dates: [],
      consultant: null,
      times: [],
      date: null,
      time: "",
      error_e1: true,
      isLoading: false,
      consultantList: [],
      dateAll: [],
      form: {
        consultant_type: "",
        mentor_id: "",
        date: "",
        time: "",
      },
      name_days: [
        "อาทิตย์",
        "จันทร์",
        "อังคาร",
        "พุธ",
        "พฤหัสบดี",
        "ศุกร์",
        "เสาร์",
      ],
      monthNamesThai: [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ],
    };
  },
  created() {
    this.getConsultants();
  },

  methods: {
    getDaysInMonth(y, m) {
      var year = y;
      var month = m;

      var date = new Date(year, month, 1);
      var days = [];
      while (date.getMonth() === month) {
        days.push(new Date(date));
        this.dates.push({
          dayName: this.name_days[date.getDay()],
          monthName: this.monthNamesThai[date.getMonth()],
          day: moment(date).format("DD"),
          month: moment(date).format("MM"),
          year: moment(date).format("YYYY"),
        });
        date.setDate(date.getDate() + 1);
      }
    },
    getConsultants() {
      this.isLoading = true;
      SERVICE.cases
        .getConsultants(this.form)
        .then((res) => {
          this.isLoading = false;
          if (res.data.check_personal_appointment) {
            this.$router.go(-1);
          }
          this.consultantList = res.data.consultant_list;
          this.dateAll = res.data.date_all;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    onSelectConsultant(item) {
      this.form.mentor_id = item.id;
      this.consultant = item;
      this.form.date = "";
      this.form.time = "";
    },

    submit() {
      this.isLoading = true;
      SERVICE.cases
        .savePersonalAppointment(this.form)
        .then((res) => {
          this.isLoading = false;
          if (res === "success") {
            this.dialog = true;
            // this.$swal({
            //   title: "บันทึกสำเร็จ",
            //   text: "สร้างนัดหมายปรึกษาปัญหาส่วนตัวเรียบร้อยแล้ว",
            //   icon: "success",
            //   confirmButtonText: "ตกลง",
            // }).then(() => {
            //   this.$router.push("/personal-appointment");
            // });
          } else if (res.response.status === 400) {
            this.$swal({
              title: "ไม่สามารถสร้างนัดหมายได้!",
              text: res.response.data,
              icon: "error",
            });
            this.isLoading = false;
          } else {
            this.$swal({
              title: "บันทึกไม่สำเร็จ!",
              text: "มีบางอย่างผิดพลาด",
              icon: "error",
            });
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    closeDialog() {
      this.dialog = false;
      this.$router.push("/personal-appointment");
    },
    next(page) {
      const valid = this.valid(page);
      if (valid.status) {
        this.error_e1 = false;
        this.$swal({
          title: valid.msg,
          icon: "warning",
          confirmButtonText: "ตกลง",
        });
      } else {
        this.error_e1 = true;
        this.e1 = page;
        window.scrollTo(0, 0);
      }
    },

    back(page) {
      this.e1 = page;
    },

    valid(page) {
      switch (page) {
        case 2:
          if (!this.form.consultant_type) {
            return { status: true, msg: "กรุณาเลือกประเภทที่ปรึกษา" };
          } else if (
            !this.form.mentor_id &&
            this.form.consultant_type === "ระบุผู้ให้คำปรึกษา"
          ) {
            return { status: true, msg: "กรุณาเลือกที่ปรึกษา" };
          } else {
            return { status: false, msg: "" };
          }
        case 3:
          if (!this.form.date) {
            return { status: true, msg: "กรุณาเลือกวันที่" };
          } else if (!this.form.time) {
            return { status: true, msg: "กรุณาเลือกช่วงเวลา" };
          } else {
            return { status: false, msg: "" };
          }
        default:
          return { status: false, msg: "" };
      }
    },
    setValueDate(e, i) {
      console.log(e);
      this.times = e;
      this.form.date = i;
      this.form.time = "";
    },
    clearConsultants() {
      this.form.mentor_id = "";
      this.form.date = "";
      this.form.time = "";
      this.consultant = null;
    },
    convertDayName(date) {
      return this.name_days[new Date(date).getDay()];
    },
    convertDate(date) {
      return (
        moment(date).format("DD") +
        " " +
        this.monthNamesThai[new Date(date).getMonth()]
      );
    },
    fullDateThai(date) {
      var d =
        "วัน" +
        this.convertDayName(date) +
        " ที่ " +
        this.convertDate(date) +
        " " +
        moment(date).format("YYYY");

      return d;
    },
  },
};
</script>

<style lang="scss">
.mental__form {
  background: linear-gradient(180deg, #ffffff 0%, #edf0f6 100%);
  min-height: calc(100vh - 236px);

  .consultant_profile_image {
    border-radius: 100%;
    width: 80px;
    height: 80px;
  }

  .active_select_date {
    background: #d8effe !important;
    border: 1px solid #4090ff;
    box-shadow: 0px 3px 5px rgba(64, 144, 255, 0.5);
    border-radius: 5px;
    color: #4090ff !important;
  }
  .select_date {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 98px;
    min-height: 70px;
    background: #ffffff;
    box-shadow: 0px 3px 5px rgba(64, 144, 255, 0.5);
    border-radius: 5px;
    transition: all 100ms ease-in-out;
    color: #5e616f;
  }
  .select_date:hover {
    background: #d8effe;
    border: 1px solid #4090ff;
    box-shadow: 0px 3px 5px rgba(64, 144, 255, 0.5);
    border-radius: 5px;
  }
  .select_time {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 205px;
    min-height: 44px;
    background: #ffffff;
    box-shadow: 0px 3px 5px rgba(64, 144, 255, 0.5);
    border-radius: 5px;
    transition: all 100ms ease-in-out;
    color: #5e616f;
  }
  .select_time:hover {
    background: #d8effe;
    border: 1px solid #4090ff;
    box-shadow: 0px 3px 5px rgba(64, 144, 255, 0.5);
    border-radius: 5px;
  }

  .consultant_card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border-radius: 5px;
    min-height: 211px;
    padding: 20px;
    transition: all 100ms ease-in-out;
  }
  .consultant_card:hover {
    border: 2px solid #4090ff;
    cursor: pointer;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    filter: drop-shadow(0px 3px 5px rgba(64, 144, 255, 0.5));
  }
  .consultant_card_active {
    border: 2px solid #4090ff;
    cursor: pointer;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    filter: drop-shadow(0px 3px 5px rgba(64, 144, 255, 0.5));
  }
  .mental_checkbox_card {
    height: 92px;
    width: 100%;
    background: #ffffff;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0px 12px;
    .slot_mental_checkbox_card {
      height: 92px;
      width: 100%;
      background: #ffffff;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 0px 12px;
    }
  }
  .theme--light.v-stepper .v-stepper__step__step {
    color: white;
    width: 42px !important;
    height: 42px !important;
    font-size: 24px !important;
    font-weight: 500 !important;
  }

  .theme--light.v-stepper
    .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error)
    .v-stepper__step__step {
    background: #edf0f6;
    color: #4090ff;
  }

  .theme--light.v-stepper .v-stepper__header .v-divider {
    border-color: #edf0f6;
  }

  .v-application .primary {
    background-color: #4090ff !important;
    border-color: #4090ff !important;
  }

  span.v-stepper__step__step.primary {
    background: #4090ff !important;
  }

  .v-stepper--alt-labels .v-stepper__header .v-divider {
    margin: 44px -67px 0;
    align-self: flex-start;
    border: 2px solid;
  }
  .v-stepper__header {
    height: 72px;
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-shadow: unset !important;
  }
  .theme--li .mental__step {
    .v-stepper__header {
      box-shadow: unset !important;
    }
    .active_line_stepper {
      .theme--light.v-stepper .v-stepper__header .v-divider {
        border-color: #4090ff !important;
      }
    }
  }
  .theme--light.v-stepper .v-stepper__step--active .v-stepper__label {
    text-shadow: 0px 0px 0px black;
    span {
      // color: #4090ff !important;
    }
  }

  .theme--light.v-stepper {
    background-color: transparent !important;
  }
  .v-stepper__wrapper {
    overflow: unset !important;
    transition: none;
  }
  .v-stepper__items {
    position: relative;
    overflow: unset !important;
  }

  @media only screen and (max-width: 959.98px) {
    .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
      display: block;
    }
  }
}
.consultant_name {
  font-family: "Prompt";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #5e616f;
}
.consultant_profession {
  font-family: "Prompt";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #4090ff;
}
.consultant_institution {
  font-family: "Prompt";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #5e616f;
}
</style>
