import Service from "../service";
const url = 'event-news'
const eventAndNews = {
    get(data) {
        return Service.get(url, { params: data });
    },
    show(data) {
        return Service.get(`${url}/${data}`);
    },
}
export default eventAndNews