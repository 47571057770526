<template>
  <div>
    <v-row v-if="$vuetify.breakpoint.mdAndUp" align="stretch">
      <v-col
        style="padding-left: 5px !important; padding-right: 5px !important"
        v-for="(item, i) in eventAndNews"
        :key="i"
        cols="12"
        md="3"
      >
        <NewsPanel
          :model="item"
          :image="item.image"
          :topic="item.name_events_news"
          :content="item.content"
          :date="item.date_events"
        ></NewsPanel>
      </v-col>
    </v-row>
    <div v-else>
      <carousel
        :margin="10"
        :stagePadding="40"
        v-if="eventAndNews.length > 0"
        :items="1"
        :nav="false"
      >
        <NewsPanel
          v-for="item in eventAndNews"
          :key="item.id"
          :model="item"
          :image="item.image"
          :topic="item.name_events_news"
          :content="item.content"
          :date="item.date_events"
        ></NewsPanel>
      </carousel>
    </div>

    <v-row class="mb-10 mt-8" justify="center">
      <v-col cols="auto">
        <v-btn
          min-width="228px"
          height="44px"
          @click="goto('event-news')"
          class="mx-3 btn-blue"
          rounded
        >
          ดูกิจกรรมและข่าวสารทั้งหมด >
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import NewsPanel from "./NewsPanel.vue";
import carousel from "vue-owl-carousel";
import { mapState } from "vuex";
export default {
  components: {
    carousel,
    NewsPanel,
  },
  computed: {
    ...mapState({
      eventAndNews: (state) => state.EventAndNews.eventAndNewsList,
      access_token: (state) => state.auth.access_token,
    }),
  },
  data() {
    return {
      row: 4,
    };
  },
  async created() {
    await this.$store.dispatch("EventAndNews/get", {
      row: this.row,
    });
  },
  methods: {
    goto(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style></style>
