<template>
  <div>
    <Loading :loading="isLoading"></Loading>
    <v-container class="mw-1150">
      <BreadCrumbs :items="items"></BreadCrumbs>
      <div class="text-center my-10">
        <div class="font-40 font-w-600 text-black" v-html="data.topic"></div>
        <v-row class="my-3" justify="center" v-if="data">
          <v-col cols="auto" v-for="(cate, i) in data.category" :key="i">
            <MediaBadge :category="cate.name" :id="cate.id"></MediaBadge>
          </v-col>
        </v-row>
      </div>
      <div class="d-flex justify-space-between my-3 font-13 text-black">
        <div><i class="bi bi-book"></i> ผู้เขียน {{ data.author_name }}</div>
        <div>
          <i class="bi bi-calendar-minus"></i> อัปเดตล่าสุด
          {{ data.release_date | moment("DD/MM/YYYY") }}
        </div>
      </div>
      <div class="media-article" v-html="data.content"></div>

      <div class="my-5">
        <Topic
          eng="Related Knowledge Media"
          thai="สื่อความรู้ ที่เกี่ยวข้อง"
        ></Topic>
        <v-row class="mt-3">
          <v-col
            style="padding-left: 6px !important; padding-right: 6px !important"
            cols="12"
            md="4"
            lg="3"
            v-for="(item, i) in this.data.nearby"
            :key="i"
          >
            <MediaPanel
              :image="item.image"
              :date="item.release_date"
              :topic="item.topic"
              :id="item.id"
            ></MediaPanel>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import Topic from "../components/Topic.vue";
import moment from "moment";
import MediaBadge from "../components/Media/MediaBadge.vue";
import Loading from "../components/Loading.vue";
import BreadCrumbs from "../components/BreadCrumbs.vue";
import MediaPanel from "../components/Media/MediaPanel.vue";
import { mapState } from "vuex";

export default {
  components: {
    MediaBadge,
    Loading,
    BreadCrumbs,
    Topic,
    MediaPanel,
  },
  computed: {
    ...mapState({
      category: (state) => state.Media.categories,
    }),
  },
  data() {
    return {
      moment: moment,
      isLoading: false,
      data: {
        id: "",
        topic: "",
        release_date: "",
        content: "",
      },
      items: [
        {
          text: "หน้าแรก",
          disabled: false,
          href: "/",
        },
        {
          text: "สื่อความรู้",
          disabled: false,
          href: "/media",
        },
      ],
    };
  },
  async created() {
    const mediaId = this.$route.params.id;
    this.isLoading = true;
    if (mediaId) {
      await this.$store.dispatch("Media/show", mediaId).then((res) => {
        this.data = res;
        let html = res.topic;
        let div = document.createElement("div");
        div.innerHTML = html;
        let text = div.textContent || div.innerText || "";
        this.items.push({
          text: text,
          disabled: true,
        });
      });
    }
    this.isLoading = false;
  },
};
</script>

<style></style>
