<template>
  <div>
    <div class="topic-secondary">{{eng}}</div>
    <div class="pl-5 topic-border">
      <div class="topic-primary">{{thai}}</div>
    </div>
  </div>
</template>

<script>
export default {
    props:['eng','thai']
};
</script>

<style>
</style>